import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { AppState, uploadFloorplan, uploadRoomplan } from '@ngrx-store';
import { Store } from '@ngrx/store';

@Directive({
  selector: '[appUploadSpaceplan]',
})
export class UploadSpaceplanDirective implements OnInit {
  @Input() locationId = '';
  @Input() floorId = '';
  @Input() roomId = '';
  accept = 'image/svg+xml';

  @HostListener('change', ['$event'])
  onFileChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files?.[0];
    if (file && this.locationId && this.floorId) {
      const dispatchAction = this.roomId
        ? uploadRoomplan({ locationId: this.locationId, floorId: this.floorId, file, roomId: this.roomId })
        : uploadFloorplan({ locationId: this.locationId, spaceId: this.floorId, file });

      this.store.dispatch(dispatchAction);
    }
  }

  constructor(private elementRef: ElementRef, private store: Store<AppState>) {}

  ngOnInit() {
    this.elementRef.nativeElement.addEventListener('change', this.onFileChange);
  }
}
