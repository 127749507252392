<div class="mb-3 flex items-center">
  <mat-button-toggle-group
    [value]="incidentsFilterService.incidentTabStatusFilter"
    class="mobile-main-toggle-group"
    name="Incident status"
    (change)="incidentsFilterService.incidentTabStatusFilter = $event.value">
    <mat-button-toggle [value]="incidentsFilterService.incidentTabStatusFilterEnum.OPEN">
      Open
      <span *ngIf="incidentsFilterService.newIncidentsAmount" class="incidents-amount-indicator ml-2">{{
        incidentsFilterService.newIncidentsAmount
      }}</span>
      <span
        *ngIf="incidentsFilterService.inProgressIncidentsAmount"
        class="incidents-amount-indicator bg-ui-yellow-100 text-ui-yellow-350 ml-2"
        >{{ incidentsFilterService.inProgressIncidentsAmount }}</span
      >
    </mat-button-toggle>
    <mat-button-toggle [value]="incidentsFilterService.incidentTabStatusFilterEnum.HISTORY">
      History
    </mat-button-toggle>
  </mat-button-toggle-group>
  <mat-spinner *ngIf="incidentsFilterService.resolvedIncidentsLoading" diameter="40" class="ml-1"></mat-spinner>
</div>

<div class="mb-3 flex items-center">
  <app-search-input
    style="width: 100%"
    placeholder="Enter Ticket №"
    (searchDataEvent)="incidentsFilterService.ticketNumberSearch($event)"
    inputWidth="100%" />
  <button
    class="toggle-table-filter-btn ml-3"
    [class.has-selected-filters]="
      incidentsFilterService.incidentTabStatusFilter === incidentTabStatusFilterEnum.OPEN
        ? incidentsFilterService.selectedFilters
        : incidentsFilterService.resolvedIncidentsSelectedFilters
    "
    (click)="openIncidentFiltersBottomSheet()">
    <i class="icon-filter_Menu text-3xl"></i>
  </button>
</div>

<app-incidents-table
  noDataText="All the Building devices are working properly"
  [isNoDataBigSize]="true"
  [isMobile]="true" />
