import { MenuItemModel } from '@models';
export const MobileMenuItems: MenuItemModel[] = [
  {
    itemIndex: 1,
    title: 'Dashboard',
    icon: 'icon-dashboard',
    url: 'dashboard',
  },
  {
    itemIndex: 2,
    title: 'Devices',
    icon: 'icon-devices',
    url: 'devices',
  },
  {
    itemIndex: 3,
    title: 'Incidents',
    icon: 'icon-incidents',
    url: 'incidents',
    showIncidentsAmount: true,
  },
  {
    itemIndex: 4,
    title: 'Users',
    icon: 'icon-users-management',
    url: 'user-management',
  },
];
