<div
  class="relative border-ui-dark"
  [class.border]="isImgLoaded"
  #container
  *ngIf="clientId && locationId && spaceplan.id && spaceplan.documentReference">
  <img
    alt="floor plan"
    #img
    [src]="
      'clientId/' +
        clientId +
        '/location/' +
        locationId +
        '/space/' +
        spaceplan.id +
        '/document/' +
        spaceplan.documentReference
        | srcSecurePipe
        | async
    "
    class="select-none pointer-events-none w-full"
    [class.h-0]="!isImgLoaded" />
  @if (spaceplan.attachments) {
  <ng-container *ngFor="let attachment of spaceplan.attachments | filterById : currentAttachmentId">
    @let isRoomAttachment = !!(attachment.type);
    <app-spaceplan-attachment
      class="pointer-events-none"
      [allowNavigation]="!currentAttachmentId"
      [editMode]="attachment.id === editAttachmentId"
      [ngStyle]="{ 'z-index': attachment.id === editAttachmentId ? 1 : 0 }"
      [attachment]="attachment"
      [containerWidth]="width"
      [containerHeight]="height"
      [popover]="popover"
      [routerLink]="
        isRoomAttachment
          ? ['/dashboard', locationId, spaceplan.id, attachment.id]
          : ['/devices', locationId, attachment.id]
      "
      [popoverData]="canEdit
            ? {
                locationId,
                attachmentName: attachment.friendlyName,
                id: attachment.id,
                dialogTitle: isRoomAttachment ? 'Unassign a Room' : 'Unassign a Device',
                dialogText:  isRoomAttachment
                  ? 'Are you sure you want to unassign a room [' + attachment.friendlyName + '] from the floor plan?'
                  : 'Are you sure you want to unassign a device [' + attachment.friendlyName + '] from the block diagram?'
              }
            : {
              details: attachment,
              floorId: spaceplan.id,
              locationId: locationId,
              allowNavigation: !currentAttachmentId
            }"
      (confirmEvent)="confirm($event, isRoomAttachment)"
      (cancelEvent)="resetAttachmentId()" />
  </ng-container>
  }
</div>
<ngx-skeleton-loader *ngIf="!isImgLoaded" [theme]="{ height: '400px' }" />
<app-delete-spaceplan
  *ngIf="isImgLoaded && !isMobile"
  [documentReference]="spaceplan.documentReference"
  [floorId]="spaceplan.parentSpaceId || spaceplan.id"
  [roomId]="spaceplan.parentSpaceId ? spaceplan.id : ''"
  [locationId]="locationId"
  class="mt-4 ml-2" />
