<div
  *ngIf="currentFloor && locationId"
  #wrapper
  [class]="
    isFullScreen
      ? 'fixed top-0 left-0 w-full h-full z-10 bg-white overflow-auto floor-plan-full-screen'
      : 'relative overflow-hidden'
  ">
  <button
    class="test-enter-fullscreen-btn btn-white-outlined btn-medium w-10 p-0 absolute right-4 top-4 z-10"
    *ngIf="!isFullScreen"
    (click)="enterFloorplanFullScreen()">
    <i class="icon-ic_zoom1 text-3xl"></i>
  </button>
  <button
    class="test-exit-fullscreen-btn exit-fullscreen-btn btn-white-outlined btn-medium w-10 p-0 rounded-full fixed left-4 top-4 z-10"
    *ngIf="isFullScreen"
    (click)="exitFloorplanFullScreen()">
    <i class="icon-arrow-left text-3xl"></i>
  </button>
  <div class="test-zoom-btn-wrapper fixed flex flex-row left-1/2 bottom-6 -translate-x-1/2 z-10" *ngIf="isFullScreen">
    <button
      class="test-increese-zoom-btn btn-white-outlined btn-medium w-10 p-0 mr-6 text-3xl"
      [class.btn-disabled]="zoom === maxZoom"
      [disabled]="zoom === maxZoom"
      (click)="zoomIncrease()">
      +
    </button>
    <button
      class="test-decreese-zoom-btn btn-white-outlined btn-medium w-10 p-0 text-3xl"
      [disabled]="zoom === minZoom"
      (click)="zoomDecrease()">
      -
    </button>
  </div>
  <app-spaceplan
    class="block"
    [style.width]="isFullScreen ? 100 + zoom * zoomPercentStep + '%' : '100%'"
    [class.overflow-hidden]="isFullScreen"
    [spaceplan]="{
      id: currentFloor.id,
      documentReference: currentFloor.documentReference,
      attachments: currentFloor.rooms || [],
      parentSpaceId: currentFloor.parentSpaceId
    }"
    [locationId]="locationId"
    [currentAttachmentId]="roomId"
    [canEdit]="false"
    [popover]="roomPopover"
    [isMobile]="true" />
</div>
