import { Component } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SkeletonTableComponent } from '@standalone/skeleton-table/skeleton-table.component';

@Component({
  selector: 'app-mobile-space-skeleton-animation',
  standalone: true,
  imports: [NgxSkeletonLoaderModule, SkeletonTableComponent],
  templateUrl: './space-skeleton-animation.component.html',
  styles: [],
})
export class SpaceSkeletonAnimationComponent {}
