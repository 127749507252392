import { PhoneNumberClass } from '@abstraction';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  COUNTRY_LIST,
  DEFAULT_COUNTRY,
  emailValidationPattern,
  passwordPolicy,
  removeSpacesValidator,
  userLockOutPolicyId,
} from '@app-lib';
import { rolesRules, UserClient, UserModel, UserRolesIds } from '@models';
import { addClientUser, AppState, clientUserIsLoading, getClient, selectUser } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { AppService } from '@services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mobile-add-new-user',
  templateUrl: './add-new-user.component.html',
})
export class AddNewUserComponent extends PhoneNumberClass {
  readonly COUNTRY_LIST = COUNTRY_LIST;
  roleOptions = rolesRules;

  form: FormGroup;
  isLoading$: Observable<boolean>;
  currentClient$: Observable<UserClient | undefined>;
  user$: Observable<UserModel>;

  constructor(private fb: FormBuilder, private store: Store<AppState>, private appService: AppService) {
    super();
    this.form = this.fb.group({
      firstName: ['', [Validators.required, Validators.min(3), removeSpacesValidator]],
      lastName: ['', [Validators.required, Validators.min(3), removeSpacesValidator]],
      email: ['', [Validators.required, Validators.pattern(emailValidationPattern)]],
      phone: this.fb.group({
        number: ['', [Validators.required]],
        countryCode: [DEFAULT_COUNTRY.code, [Validators.required]],
      }),
      company: [''],
      passwordPolicyId: passwordPolicy,
      lockoutPolicyId: [userLockOutPolicyId],
      roleId: [UserRolesIds.ReadOnly, [Validators.required]],
      notificationsPreferences: this.fb.group({
        emailEnabled: [false, [Validators.required]],
        smsEnabled: [false, [Validators.required]],
      }),
    });
    this.user$ = this.store.select(selectUser);
    this.currentClient$ = this.store.select(getClient(this.appService.currentClient));
    this.isLoading$ = this.store.select(clientUserIsLoading);
  }

  get isValidForm(): boolean {
    return this.form.valid;
  }

  submit() {
    if (this.isValidForm) {
      this.store.dispatch(addClientUser({ newUserData: this.form.value, clientId: this.appService.currentClient }));
    } else {
      this.form.markAllAsTouched();
    }
  }
}
