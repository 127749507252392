@let isPortrait = appService.screenOrientation === ScreenOrientations.PORTRAIT;
<h1 *ngIf="isPortrait" class="mobile-page-main-title mb-3">Dashboard</h1>

<div *ngIf="locationsLoaded$ | async; else skeleton" class="test-locations-table-wrapper h-full">
  <div class="border-b-ui-gray-light border-b flex items-center justify-between mb-3">
    <mat-button-toggle-group
      class="mobile-toggle-group"
      aria-label="Dashboard Tabs"
      [value]="tab"
      (change)="tab = $event.value">
      <mat-button-toggle value="table" class="test-table-toggle-button">
        <span class="item-status-title">Table View</span>
      </mat-button-toggle>
      <mat-button-toggle value="map" class="test-map-toggle-button">
        <span class="item-status-title">Map View</span>
      </mat-button-toggle>
    </mat-button-toggle-group>
    <button
      *ngIf="tab === 'table'"
      class="toggle-table-filter-btn test-open-building-filters"
      [class.has-selected-filters]="selectedFilters"
      (click)="openBuildingFiltersBottomSheet()">
      <i class="icon-filter_Menu text-3xl"></i>
    </button>
    <button
      *ngIf="tab === 'map'"
      class="toggle-table-filter-btn test-open-map-filters"
      [class.has-selected-filters]="mapFilterService.mapFilter.length"
      (click)="openMapFiltersBottomSheet()">
      <i class="icon-filter_Menu text-3xl"></i>
    </button>
  </div>
  <div [ngClass]="{ 'is-hidden-tab': tab === 'map' }" class="test-building-tab-wrapper">
    <div class="mb-3">
      <app-search-input
        placeholder="Building name"
        (searchDataEvent)="buildingDataFilterService.search = $event"
        inputWidth="100%" />
    </div>
    <app-site-table />
  </div>
  <!-- 106px is height of .mobile-page-main-title and .mobile-toggle-group -->
  <div
    class="test-map-tab-wrapper"
    [ngClass]="{
    'is-hidden-tab': tab === 'table',
    'test-fullscreen-on fixed top-0 left-0 w-full h-full z-10': isFullScreen,
    'relative h-[calc(100%-150px)]': tab === 'map' && !isFullScreen,
    'relative h-[calc(100%-60px)]': tab === 'map' && !isFullScreen && !isPortrait,
  }">
    <button
      *ngIf="isFullScreen"
      class="test-exit-fullscreen toggle-table-filter-btn rounded-full absolute left-4 top-4 z-10"
      (click)="isFullScreen = false">
      <i class="icon-arrow-left text-3xl"></i>
    </button>
    <button
      *ngIf="!isFullScreen"
      class="test-enter-fullscreen toggle-table-filter-btn absolute right-4 top-4 z-10"
      (click)="isFullScreen = true">
      <i class="icon-ic_zoom1 text-3xl"></i>
    </button>
    <app-dashboard-map *ngIf="tab === 'map'" />
  </div>
</div>

<ng-template #skeleton>
  <app-skeleton-table class="test-skeleton-table-loader" [showTabs]="false" />
</ng-template>
