import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EntityStatus } from '@models';
import { DirectivesModule } from '@directives';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-mobile-mobile-buttons-bar',
  standalone: true,
  imports: [DirectivesModule, NgIf, NgClass],
  template: `
    <div class="text-nowrap">
      @if (!isArchived) {
      <button
        *ngIf="linkEnabled"
        class="link-btn bg-white text-ui-blue-100 border-ui-blue-100 header-btn"
        (click)="linkClicked()">
        <i class="icon-link action-icon"></i>
      </button>
      <button
        appRestrictedEl
        [showForClientAdmin]="true"
        *ngIf="editEnabled"
        class="edit-btn bg-ui-blue-100 header-btn"
        (click)="editClicked()">
        <i class="icon-edit action-icon"></i>
      </button>
      <button
        *ngIf="pauseEnabled"
        [ngClass]="isPaused ? 'bg-ui-green-light' : 'bg-ui-gray-100'"
        class="pause-btn header-btn"
        appRestrictedEl
        [showForClientAdmin]="true"
        (click)="pauseClicked()">
        <i [ngClass]="isPaused ? 'icon-play-16 bg-ui-green-light' : 'icon-pause-16'" class="action-icon"></i>
      </button>
      }
      <button
        *ngIf="archiveEnabled"
        class="archive-btn header-btn"
        appRestrictedEl
        [showForClientAdmin]="true"
        [ngClass]="isArchived ? 'bg-ui-green-light' : 'bg-ui-gray-100'"
        (click)="archiveClicked()">
        <i *ngIf="!isArchived" class="icon-add-to-archive action-icon"></i>
        <i *ngIf="isArchived" class="icon-restore action-icon"></i>
      </button>
    </div>
  `,
  styles: [],
})
export class MobileButtonsBarComponent {
  @Input() pauseEnabled = true;
  @Input() editEnabled = false;
  @Input() archiveEnabled = false;
  @Input() isArchived = false;
  @Input() isPaused = false;
  @Input() linkEnabled = false;
  @Output() statusTrigger = new EventEmitter<boolean>();
  @Output() editTrigger = new EventEmitter();
  @Output() linkTrigger = new EventEmitter();
  @Output() archiveTrigger = new EventEmitter();

  constructor() {}

  pauseClicked() {
    this.statusTrigger.emit();
  }

  editClicked() {
    this.editTrigger.emit();
  }

  linkClicked() {
    this.linkTrigger.emit();
  }

  protected readonly entityStatus = EntityStatus;

  archiveClicked() {
    this.archiveTrigger.emit();
  }
}
