import { Component, OnDestroy, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { deviceColumnOptions } from '@mocks';
import { AppState, deviceLoaded, selectAllDevices } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { DevicesFilterService, ExportDevicesService } from '@services';
import { Observable } from 'rxjs';
import { ExportDevicesSheetComponent } from '../../components/export-devices-sheet.component/export-devices-sheet.component';
import { DeviceFiltersSheetComponent } from '@standalone/device-filters-sheet.component/device-filters-sheet.component';
import { DevicesTableComponent } from '@standalone/_tables/devices-table/devices-table.component';

@Component({
  selector: 'app-mobile-devices-main-page',
  templateUrl: './devices-main-page.component.html',
})
export class DevicesMainPageComponent implements OnDestroy {
  deviceListLoaded$: Observable<boolean>;
  @ViewChild(DevicesTableComponent) deviceTable!: DevicesTableComponent;

  constructor(
    private store: Store<AppState>,
    public devicesFilterService: DevicesFilterService,
    private bottomSheet: MatBottomSheet,
    private exportDevicesService: ExportDevicesService
  ) {
    this.store
      .select(selectAllDevices)
      .pipe(takeUntilDestroyed())
      .subscribe(devices => {
        this.devicesFilterService.initColumns(deviceColumnOptions.map(column => column.value));
        this.devicesFilterService.initDevices(devices);
      });
    this.deviceListLoaded$ = this.store.select(deviceLoaded);

    this.exportDevicesService.export$.pipe(takeUntilDestroyed()).subscribe(type => this.exportDevices(type));
  }

  ngOnDestroy() {
    this.devicesFilterService.resetState();
  }

  exportDevices(type: 'json' | 'csv') {
    if (type === 'json') {
      this.deviceTable.exportDevicesToJSON();
    }
    if (type === 'csv') {
      this.deviceTable.exportDevicesToCSV();
    }
  }

  openDeviceFiltersBottomSheet() {
    this.bottomSheet.open(DeviceFiltersSheetComponent);
  }

  openExportDevicesBottomSheet() {
    this.bottomSheet.open(ExportDevicesSheetComponent);
  }
}
