import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterFloorByNamePipe } from './pipes/filter-floor-by-name.pipe';
import { FilterRoomsByNamePipe } from './pipes/filter-rooms-by-name.pipe';
import { FilterBuildingByNamePipe } from './pipes/filter-building-by-name.pipe';
import { FilterSpaceByFriendlyNamePipe } from './pipes/filter-space-by-friendly-name.pipe';
import { FilterClientsByNamePipe } from './pipes/filter-clients-by-name.pipe';
import { FilterFloorSpaceByNamePipe } from './pipes/filter-floor-space-by-name';
import { EmptySpacesBuildingPlanPipe } from './pipes/empty-spaces-building-plan.pipe';
import { DashboardMapMarkerIconPipe } from './pipes/dashboard-map-marker-icon.pipe';
import { SrcSecurePipePipe } from './pipes/src-secure-pipe.pipe';
import { FixRoleNamePipe } from './pipes/fix-role-name-pipe.pipe';
import { FilterByIdPipe } from './pipes/filter-by-id.pipe';
import { FilterSelectOptions } from './pipes/filter-select-options.pipe';
import { CurrentDatePipe } from './pipes/current-date.pipe';
import { FilterSpaceByStatusPipe } from './pipes/filter-space-by-status.pipe';
import { FilterDeviceByNamePipe } from './pipes/filter-device-by-name.pipe';
import { FilterDeviceByStatusPipe } from './pipes/filter-device-by-status.pipe';
import { FormatPhoneNumberPipe } from './pipes/format-phone-number.pipe';
import { SortPipe } from './pipes/sort.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FilterFloorByNamePipe,
    FilterRoomsByNamePipe,
    FilterBuildingByNamePipe,
    FilterSpaceByFriendlyNamePipe,
    FilterClientsByNamePipe,
    FilterFloorSpaceByNamePipe,
    FixRoleNamePipe,
    DashboardMapMarkerIconPipe,
    EmptySpacesBuildingPlanPipe,
    SrcSecurePipePipe,
    FilterByIdPipe,
    FilterSelectOptions,
    CurrentDatePipe,
    FilterSpaceByStatusPipe,
    FilterDeviceByNamePipe,
    FilterDeviceByStatusPipe,
    FormatPhoneNumberPipe,
    SortPipe,
  ],
  exports: [
    FilterFloorByNamePipe,
    FilterRoomsByNamePipe,
    FilterBuildingByNamePipe,
    FilterFloorSpaceByNamePipe,
    FilterSpaceByFriendlyNamePipe,
    FilterClientsByNamePipe,
    FixRoleNamePipe,
    DashboardMapMarkerIconPipe,
    EmptySpacesBuildingPlanPipe,
    SrcSecurePipePipe,
    FilterByIdPipe,
    FilterSelectOptions,
    CurrentDatePipe,
    FilterSpaceByStatusPipe,
    FilterDeviceByNamePipe,
    FilterDeviceByStatusPipe,
    FormatPhoneNumberPipe,
    SortPipe,
  ],
})
export class PipesModule {}
