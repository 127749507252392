import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { actionError, DevicesActions } from '../action-types';
import { map, switchMap } from 'rxjs';
import { AppService, DeviceService, NotificationsService } from '@services';
import {
  addNewDeviceError,
  addNewDeviceModelError,
  addNewDeviceModelSuccess,
  addNewDeviceSuccess,
  addNewManufacturerError,
  addNewManufacturerSuccess,
  allClientDevicesLoaded,
  deviceDataSuccessfullyUpdated,
  loadDeviceAttributesSuccess,
  loadDeviceCollectionBySpaceIdSuccess,
  loadDeviceDetailsSuccess,
  loadDeviceFullModelSuccess,
  loadDeviceManufacturersSuccess,
  loadDeviceModelsSuccess,
  loadDevicesError,
  updateDeviceDataError,
} from './devices.actions';
import { catchError, delay } from 'rxjs/operators';
import { DeviceData, DeviceManufacturer, DeviceModel } from '@models';
import { getLocationState } from '../locations';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state';
import { loadAllIncidents } from '../incidents';

@Injectable()
export class DevicesEffects {
  loadAllDevicesByClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.loadAllClientDevices),
      switchMap(() =>
        this.deviceService.getAllDevicesByClientId(this.appService.currentClient).pipe(
          map(devices => allClientDevicesLoaded({ devices })),
          catchError(async error => loadDevicesError({ error }))
        )
      )
    )
  );

  resetDeviceData$ = createEffect(() => this.actions$.pipe(ofType(DevicesActions.resetDeviceEntity)), {
    dispatch: false,
  });

  addNewDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.addNewDevice),
      switchMap(action =>
        this.deviceService.addNewDevice(this.appService.currentClient, action.locationId, action.newDeviceData).pipe(
          map((device: DeviceData) => {
            this.notificationService.showSuccessMessage(
              'Device [' + device.friendlyName + '] was successfully created'
            );
            return addNewDeviceSuccess({ newDevice: device });
          }),
          catchError(async error => {
            this.notificationService.showErrorMessage(error.message);
            return addNewDeviceError();
          })
        )
      )
    )
  );

  loadOneDeviceDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.loadDeviceDetails),
      switchMap(action =>
        this.deviceService
          .getDeviceDetailsByDeviceId(this.appService.currentClient, action.locationId, action.deviceId)
          .pipe(
            map(device => loadDeviceDetailsSuccess({ deviceItem: device })),
            catchError(async error => actionError({ error }))
          )
      )
    )
  );

  updateDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DevicesActions.updateDeviceData),
      switchMap(action => {
        return this.deviceService
          .updateDeviceData({
            clientId: this.appService.currentClient,
            locationId: action.locationId,
            deviceId: action.deviceId,
            data: action.data,
          })
          .pipe(
            map(device => {
              this.notificationService.showSuccessMessage('Device - ' + device.friendlyName + ' was updated');
              return deviceDataSuccessfullyUpdated({ device, oldData: action.data });
            }),
            catchError(async () => updateDeviceDataError())
          );
      })
    );
  });

  loadDeviceCollectionByRoomId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.loadDeviceCollectionBySpaceId),
      switchMap(action =>
        this.deviceService
          .getAllDevicesByClientId(this.appService.currentClient, {
            locationId: action.locationId,
            spaceId: action.roomId,
          })
          .pipe(map(devices => loadDeviceCollectionBySpaceIdSuccess({ devices })))
      )
    )
  );

  deviceDataSuccessfullyUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.deviceDataSuccessfullyUpdated),
      // TODO: get rid of delay when api is ready
      delay(5000),
      map(action => {
        if (action.oldData.status) {
          this.store.dispatch(loadAllIncidents());
        }
        return getLocationState({
          locationId: action.device.locationId || action.device.location.id,
          clientId: this.appService.currentClient,
          excludeArchived: false,
        });
      })
    )
  );

  loadDeviceManufacturers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.loadDeviceManufacturers),
      switchMap(() =>
        this.deviceService
          .getManufacturers(this.appService.currentClient)
          .pipe(map(manufacturers => loadDeviceManufacturersSuccess({ manufacturers })))
      )
    )
  );

  loadDeviceModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.loadDeviceModels),
      switchMap(() =>
        this.deviceService
          .getModels(this.appService.currentClient)
          .pipe(map(deviceModels => loadDeviceModelsSuccess({ deviceModels })))
      )
    )
  );

  loadDeviceAttributes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.loadDeviceAttributes),
      switchMap(() =>
        this.deviceService
          .getAttributes(this.appService.currentClient)
          .pipe(map(attributes => loadDeviceAttributesSuccess({ attributes })))
      )
    )
  );

  loadFullModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.loadDeviceFullModel),
      switchMap(action =>
        this.deviceService
          .getFullModel({
            clientId: action.clientId,
            manufacturerId: action.manufacturerId,
            modelId: action.modelId,
          })
          .pipe(map(fullModel => loadDeviceFullModelSuccess({ fullModel })))
      )
    )
  );

  addNewManufacturer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.addNewManufacturer),
      switchMap(action =>
        this.deviceService.addNewManufacturer(action.clientId, action.newManufacturerData).pipe(
          map((manufacturer: DeviceManufacturer) => {
            this.notificationService.showSuccessMessage(
              'Manufacturer [' + manufacturer.name + '] was successfully created'
            );
            return addNewManufacturerSuccess({ manufacturer });
          }),
          catchError(async error => {
            this.notificationService.showErrorMessage(error.message);
            return addNewManufacturerError();
          })
        )
      )
    )
  );

  addNewDeviceModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.addNewDeviceModel),
      switchMap(action =>
        this.deviceService.addNewDeviceModel(action.clientId, action.makeId, action.newDevicdeModelData).pipe(
          map((deviceModel: DeviceModel) => {
            this.notificationService.showSuccessMessage('Model [' + deviceModel.name + '] was successfully created');
            return addNewDeviceModelSuccess({ deviceModel });
          }),
          catchError(async error => {
            this.notificationService.showErrorMessage(error.message);
            return addNewDeviceModelError();
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private deviceService: DeviceService,
    private appService: AppService,
    private notificationService: NotificationsService,
    private store: Store<AppState>
  ) {}
}
