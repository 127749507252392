import { Component, DestroyRef, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { IncidentTabStatusFilter } from '@models';
import { AppService, IncidentsFilterService, IncidentsService } from '@services';
import { IncidentFiltersSheetComponent } from '@app-mobile/modules/incidents/components/incident-filters-sheet/incident-filters-sheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AppState, selectLocationIncidents, selectSpaceIncidents } from '@ngrx-store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-mobile-incidents-tab',
  templateUrl: './incidents-tab.component.html',
})
export class IncidentsTabComponent implements OnDestroy, OnInit {
  @Input() locationId: string | null = null;
  @Input() floorId: string | null = null;
  @Input() roomId: string | null = null;
  protected readonly incidentTabStatusFilterEnum = IncidentTabStatusFilter;
  incidentTableColumns = ['status', 'faultname', 'deviceId', 'floor', 'room', 'deviceType', 'reported'];
  destroyRef = inject(DestroyRef);

  constructor(
    public incidentsFilterService: IncidentsFilterService,
    private bottomSheet: MatBottomSheet,
    public store: Store<AppState>,
    public incidentsService: IncidentsService,
    public appService: AppService
  ) {}

  ngOnDestroy(): void {
    this.incidentsFilterService.resetState();
  }

  openIncidentFiltersBottomSheet() {
    this.bottomSheet.open(IncidentFiltersSheetComponent);
  }

  loadIncidentsData() {
    if (this.locationId) {
      const storeSelectorFn = this.roomId || this.floorId ? selectSpaceIncidents : selectLocationIncidents;

      this.store
        .select(storeSelectorFn(this.roomId || this.floorId || this.locationId))
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(incidents => {
          this.incidentsFilterService.initOpenIncidents(incidents);
        });
      this.incidentsFilterService.resolvedDates$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(value => this.getResolvedIncidents(value));
    }
  }

  getResolvedIncidents(dateRange: { startDateTime: string; endDateTime: string }) {
    if (dateRange.startDateTime && dateRange.endDateTime && this.locationId) {
      this.incidentsFilterService.resolvedIncidentsLoading = true;

      const requestParams: Record<string, string> = {
        ...dateRange,
      };

      const spaceId = this.roomId || this.floorId;

      if (spaceId) {
        requestParams['spaceId'] = spaceId;
      } else {
        requestParams['locationId'] = this.locationId;
      }

      this.incidentsService
        .getAllResolvedIncidents(this.appService.currentClient, requestParams)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: data => {
            this.incidentsFilterService.initResolvedIncidents(data);
            this.incidentsFilterService.resolvedIncidentsLoading = false;
          },
          error: () => {
            this.incidentsFilterService.resolvedIncidentsLoading = false;
          },
        });
    }
  }

  ngOnInit(): void {
    this.incidentsFilterService.initFilterTableType({
      isBuildingTable: Boolean(this.locationId),
      isFloorTable: Boolean(this.floorId),
      isRoomTable: Boolean(this.roomId),
      displayedColumns: this.incidentTableColumns,
    });
    this.loadIncidentsData();
  }
}
