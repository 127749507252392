import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevicesMainPageComponent } from './containers/devices-main-page/devices-main-page.component';
import { RouterModule } from '@angular/router';
import { deviceDetailsResolver, deviceResolver } from '@ngrx-store';
import { ExportDevicesSheetComponent } from './components/export-devices-sheet.component/export-devices-sheet.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PipesModule } from '@pipes';
import { DeviceDetailsComponent } from './containers/device-details/device-details.component';
import { NestedPageHeaderComponent } from '@standalone/nested-page-header/nested-page-header.component';
import { MobileButtonsBarComponent } from '@standalone/mobile-buttons-bar/mobile-buttons-bar.component';
import { SpaceSkeletonAnimationComponent } from '@standalone/space-skeleton-animation/space-skeleton-animation.component';
import { MatCard } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DeviceInfoComponent } from './components/device-info/device-info.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SkeletonTableComponent } from '@standalone/skeleton-table/skeleton-table.component';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { ImgLoaderComponent } from '@standalone/img-loader/img-loader.component';
import { BottomSheetMobileHeaderComponent } from '@standalone/bottom-sheet-mobile-header/bottom-sheet-mobile-header.component';
import { EntityStatusComponent } from '@standalone/entity-status/entity-status.component';
import { IncidentStatusComponent } from '@standalone/incident-status/incident-status.component';
import { DeviceStatsChartComponent } from '@standalone/device-stats-chart/device-stats-chart.component';
import { DevicesTableComponent } from '@standalone/_tables/devices-table/devices-table.component';
import { IncidentsTableComponent } from '@standalone/_tables/incidents-table/incidents-table.component';

@NgModule({
  declarations: [DevicesMainPageComponent, ExportDevicesSheetComponent, DeviceDetailsComponent, DeviceInfoComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: DevicesMainPageComponent,
        data: { showMenu: true },
        resolve: { deviceResolver },
      },
      {
        path: ':locationId/:deviceId',
        component: DeviceDetailsComponent,
        data: { showMenu: false },
        resolve: { deviceDetailsResolver },
      },
    ]),
    MatExpansionModule,
    MatCheckboxModule,
    PipesModule,
    NestedPageHeaderComponent,
    MobileButtonsBarComponent,
    SpaceSkeletonAnimationComponent,
    MatCard,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    SkeletonTableComponent,
    SearchInputComponent,
    ImgLoaderComponent,
    BottomSheetMobileHeaderComponent,
    EntityStatusComponent,
    IncidentStatusComponent,
    DeviceStatsChartComponent,
    DevicesTableComponent,
    IncidentsTableComponent,
  ],
})
export class DevicesModule {}
