<app-bottom-sheet-mobile-header (closeEvent)="close()" />
<div class="mobile-action-sheet" *ngIf="data.currentFloor as currentFloor">
  <div class="mobile-action-sheet-search-bar">
    <app-search-input inputWidth="100%" (searchDataEvent)="search = $event" />
  </div>

  <ul
    class="mobile-single-filter-select mobile-action-sheet-content"
    *ngIf="currentFloor.rooms | filterRoomsByName : search as rooms ">
    @if (rooms?.length) {
    <li
      *ngFor="let room of rooms"
      [class.checked]="room.id === data.roomId"
      [routerLink]="['/dashboard',data.locationData.id,currentFloor.id,room.id]"
      (click)="openRoom()">
      <span>{{ room.friendlyName }}</span>
      <i class="icon-check text-ui-green-light text-3xl"></i>
    </li>
    } @else {
    <div class="text-base text-ui-gray-100 pt-2 text-center pb-6">No Results</div>
    }
  </ul>
</div>
