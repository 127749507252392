@if (isSuperAdmin) { @let clientList = clientList$ | async ; @let selectedClient = selectedClient$ | async ; @if
(clientList?.length) {
<button
  mat-button
  class="max-w-[100px] rounded-lg bg-ui-gray-light p-2 flex test-client-list-mobile"
  (click)="openClientList(clientList, selectedClient)">
  <mat-icon
    matSuffix
    iconPositionEnd
    class="arrow-down"
    [fontIcon]="listOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'" />
  <div class="whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[60px]">{{ selectedClient?.friendlyName }}</div>
</button>
} }
