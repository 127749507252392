import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FloorSpaceModel } from '@models';
import { RoomPopoverComponent } from '@standalone/room-popover/room-popover.component';

@Component({
  selector: 'app-mobile-floorplan-tab',
  templateUrl: './floorplan-tab.component.html',
})
export class FloorplanTabComponent {
  @Input() currentFloor: FloorSpaceModel | undefined;
  @Input() locationId: string | undefined;
  @Input() roomId = '';

  @ViewChild('wrapper') wrapper: ElementRef | undefined;
  roomPopover = RoomPopoverComponent;

  minZoom = 0;
  maxZoom = 10;
  zoomPercentStep = 20;
  zoom = 4;

  isFullScreen = false;

  enterFloorplanFullScreen() {
    this.isFullScreen = true;
  }

  exitFloorplanFullScreen() {
    if (this.wrapper) {
      this.wrapper.nativeElement.scrollLeft = 0;
    }
    this.isFullScreen = false;
  }

  zoomIncrease() {
    this.zoom += 1;
  }

  zoomDecrease() {
    this.zoom -= 1;
  }
}
