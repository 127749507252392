import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { IncidentModel, IncidentNoteModel, UserClient } from '@models';
import { AppState, clientList, loadAllIncidents } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { AppService, IncidentsService, NotificationsService } from '@services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mobile-incident-details',
  templateUrl: './incident-details.component.html',
  styleUrl: './incident-details.component.scss',
})
export class IncidentDetailsComponent {
  notes: IncidentNoteModel[] = [];
  incident: IncidentModel | undefined;
  noteDescription = '';
  incidentId = '';
  postNoteLoading = false;
  destroyRef = inject(DestroyRef);
  tab: 'ticket' | 'device' | 'notes' = 'ticket';
  clients$: Observable<UserClient[] | undefined>;

  constructor(
    private notificationService: NotificationsService,
    private route: ActivatedRoute,
    private incidentService: IncidentsService,
    private appService: AppService,
    private store: Store<AppState>
  ) {
    this.clients$ = this.store.select(clientList);
    const incidentId = this.route.snapshot.paramMap.get('id');
    if (incidentId) {
      this.incidentId = incidentId;
      this.getIncident();

      this.incidentService
        .getIncidentNotes(this.appService.currentClient, incidentId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(data => {
          this.notes = data;
        });
    }
  }

  getIncident() {
    this.incidentService
      .getIncidentById(this.appService.currentClient, this.incidentId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: incident => {
          this.incident = incident;
        },
        error: error => {
          this.notificationService.showErrorMessage(error.message);
        },
      });
  }

  addNote() {
    this.postNoteLoading = true;
    this.incidentService
      .addIncidentNote(this.appService.currentClient, this.incidentId, { textValue: this.noteDescription })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: note => {
          this.postNoteLoading = false;
          this.noteDescription = '';
          this.notes.unshift(note);
          this.getIncident();
          this.store.dispatch(loadAllIncidents());
        },
        error: error => {
          this.postNoteLoading = false;
          this.notificationService.showErrorMessage(error.message);
        },
      });
  }
}
