export type SelectOption = {
  title: string;
  value: string;
};

export enum ScreenOrientations {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
}

export type SelectExtendedOption = SelectOption & {
  checked: boolean;
};

export type CustomHTTPResponseError = {
  name: string;
  status: number;
  message: string;
};
export enum InstanceTypes {
  DEV = 'dev',
  STAGE = 'stage',
  PROD = 'prod',
  ON_PREM = 'on prem',
}
