import { Component, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  standalone: true,
  selector: 'app-device-status-count',
  templateUrl: './device-status-count.component.html',
  styles: [],
  imports: [MatTooltip],
})
export class DeviceStatusCountComponent {
  @Input() activeAmount = 0;
  @Input() pausedAmount = 0;
  @Input() size: 'small' | 'normal' = 'normal';
}
