<app-bottom-sheet-mobile-header (closeEvent)="close()" />
<div class="mobile-action-sheet">
  <div class="mobile-action-sheet-search-bar">
    <app-search-input inputWidth="100%" (searchDataEvent)="search = $event" />
  </div>

  <ul
    class="mobile-single-filter-select mobile-action-sheet-content"
    *ngIf="data.locationData.floors | filterFloorByName : search as floors ">
    @if (floors.length) {
    <li
      *ngFor="let floor of floors"
      [class.checked]="floor.id === data.floorId"
      [routerLink]="['/dashboard',data.locationData.id,floor.id]"
      (click)="openFloor()">
      <span>{{ floor.friendlyName }}</span>
      <i class="icon-check text-ui-green-light text-3xl"></i>
    </li>
    } @else {
    <div class="text-base text-ui-gray-100 pt-2 text-center pb-6">No Results</div>
    }
  </ul>
</div>
