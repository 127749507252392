<app-mobile-nested-page-header title="Add New User" />

<form [formGroup]="form" (ngSubmit)="submit()" class="pb-14">
  <mat-card-title class="card-title-small block mb-6">Basic Info</mat-card-title>

  <div class="flex flex-col">
    <label class="input-main-label-mobile">First name:*</label>
    <mat-form-field class="input-classic w-full">
      <input formControlName="firstName" matInput placeholder="Enter first name" type="text" />
      <mat-error class="test-first-name-error">Input is not valid</mat-error>
    </mat-form-field>
  </div>

  <div class="flex flex-col">
    <label class="input-main-label-mobile">Last name:*</label>
    <mat-form-field class="input-classic w-full">
      <input formControlName="lastName" matInput placeholder="Enter last name" type="text" />
      <mat-error class="test-last-name-error">Input is not valid</mat-error>
    </mat-form-field>
  </div>

  <div class="flex flex-col" *ngIf="user$ | async as user">
    <label class="input-main-label-mobile">Role:*</label>
    <mat-form-field class="select-classic w-full" *ngIf="currentClient$ | async as client">
      <mat-select formControlName="roleId" placeholder="Select role" panelClass="single-select-overlay">
        <mat-option
          *ngFor="let role of roleOptions[user.role.name]"
          [value]="role.id"
          appSuperAdminRoleFilter
          [isManagingClient]="client?.isManagingClient">
          {{ role.name }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
      <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
    </mat-form-field>
  </div>

  <mat-card-title class="card-title-small block my-6">Contact Info</mat-card-title>

  <div class="flex flex-col">
    <label class="input-main-label-mobile">E-mail:*</label>
    <mat-form-field class="input-classic w-full">
      <input formControlName="email" matInput placeholder="Enter e-mail" type="text" />
      <mat-error class="test-email-error">Input is not valid</mat-error>
    </mat-form-field>
  </div>

  <div class="flex flex-col" formGroupName="phone">
    <label class="input-main-label-mobile">Phone number:*</label>
    <div class="flex justify-between">
      <mat-form-field class="input-classic max-w-[150px] mr-2">
        <mat-select (selectionChange)="changeMaskSettings($event)" formControlName="countryCode">
          @for (country of COUNTRY_LIST; track country.code) {
          <mat-option [value]="country.code">{{ country.code }} ({{ country.phoneCode }})</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field class="input-classic flex-grow">
        <input
          formControlName="number"
          matInput
          [shownMaskExpression]="phoneMaskExpression()"
          [mask]="phoneMask()"
          [placeholder]="phoneMask()"
          [validation]="true"
          type="text" />
        <mat-error class="test-phone-error">Input is not valid</mat-error>
      </mat-form-field>
    </div>
  </div>

  <mat-card-title class="card-title-small block my-6">Notifications</mat-card-title>

  <div formGroupName="notificationsPreferences">
    <mat-slide-toggle class="main-toggle" formControlName="emailEnabled" labelPosition="before">
      <span>Email:</span> {{ form.get('notificationsPreferences.emailEnabled')?.value ? 'On' : 'Off' }}
    </mat-slide-toggle>
  </div>

  <div class="fixed bottom-0 left-0 w-full p-4 shadow bg-ui-gray-200">
    <button
      type="submit"
      [disabled]="(isLoading$ | async) === true"
      class="test-submit-btn btn-medium btn-green w-full">
      <mat-spinner diameter="20" class="mr-1" *ngIf="(isLoading$ | async) === true"></mat-spinner>
      Save
    </button>
  </div>
</form>
