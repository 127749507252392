import { AfterContentInit, Component, Input } from '@angular/core';
import { EntityStatus, FloorSpaceModel, RoomSpaceModel } from '@models';

@Component({
  selector: 'app-mobile-building-list-item',
  templateUrl: './building-list-item.component.html',
  styleUrl: './building-list-item.component.scss',
})
export class BuildingListItemComponent implements AfterContentInit {
  @Input() floorData!: FloorSpaceModel;
  @Input() locationId!: string;
  private roomStats = {
    totalRooms: 0,
    alert: 0,
    warning: 0,
    good: 0,
    paused: 0,
  };
  progressBar = {
    totalRooms: 0,
    alert: 0,
    warning: 0,
    good: 0,
    paused: 0,
  };

  ngAfterContentInit(): void {
    this.roomStats.totalRooms = this.floorData.rooms?.length || 0;
    this.floorData.rooms?.forEach(room => this.calculateRoomStats(room));
    this.calculateProgressBarData();
  }

  private calculateRoomStats(room: RoomSpaceModel) {
    if (room.status === EntityStatus.Paused) {
      this.roomStats.paused++;
      return;
    }
    if (room.incidentCountByStatuses.newCount) {
      this.roomStats.alert++;
    } else if (room.incidentCountByStatuses.inProgressCount) {
      this.roomStats.warning++;
    } else {
      this.roomStats.good++;
    }
  }

  getStyle(value: number): object {
    return { width: `${value}%` };
  }

  private calculateProgressBarData() {
    const onePercent = this.roomStats.totalRooms / 100;
    const calculatePercent = (value: number) => Math.round(value / onePercent) || 0;
    this.progressBar.alert = calculatePercent(this.roomStats.alert);
    this.progressBar.warning = calculatePercent(this.roomStats.warning);
    this.progressBar.paused = calculatePercent(this.roomStats.paused);
    this.progressBar.good = 100 - this.progressBar.alert - this.progressBar.warning - this.progressBar.paused;
  }
}
