import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DevicesFilterService } from '@services';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { NgForOf, NgIf } from '@angular/common';
import { MatCheckbox } from '@angular/material/checkbox';
import { PipesModule } from '@pipes';
import { BottomSheetMobileHeaderComponent } from '@standalone/bottom-sheet-mobile-header/bottom-sheet-mobile-header.component';

@Component({
  selector: 'app-mobile-device-filters-sheet',
  standalone: true,
  templateUrl: './device-filters-sheet.component.html',
  imports: [
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatExpansionPanelHeader,
    NgIf,
    NgForOf,
    MatCheckbox,
    PipesModule,
    BottomSheetMobileHeaderComponent,
  ],
})
export class DeviceFiltersSheetComponent {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<DeviceFiltersSheetComponent>,
    public devicesFilterService: DevicesFilterService
  ) {}

  close() {
    this._bottomSheetRef.dismiss();
  }

  clearDeviceTypeFilter(event: Event) {
    event.stopPropagation();
    this.devicesFilterService.deviceTypeValue = [];
  }

  clearManufacturerFilter(event: Event) {
    event.stopPropagation();
    this.devicesFilterService.manufacturerValue = [];
  }

  clearSelectedBuilding(event: Event) {
    event.stopPropagation();
    this.devicesFilterService.selectedBuilding = '';
  }

  clearSelectedFloor(event: Event) {
    event.stopPropagation();
    this.devicesFilterService.selectedFloor = '';
  }

  clearSelectedRoom(event: Event) {
    event.stopPropagation();
    this.devicesFilterService.selectedRoom = '';
  }
}
