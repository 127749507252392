<app-bottom-sheet-mobile-header (closeEvent)="close()" />
<div class="mx-6 my-3 flex justify-between items-center">
  <h2 class="sheet-title">Filters</h2>
  <button *ngIf="selectedFilters" class="mobile-filter-clear-all-btn" (click)="clearAll()">Clear All</button>
</div>
<div class="mobile-action-sheet">
  <div class="mobile-action-sheet-content">
    <mat-accordion class="bottom-sheet-accordion">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="mobile-single-select-title">
              Status @if(buildingDataFilterService.statusFilter) {:
              <span class="font-normal">{{ buildingDataFilterService.statusFilter }}</span
              >}
            </div>
            <button
              *ngIf="buildingDataFilterService.statusFilter.length"
              class="mobile-filter-clear-btn"
              (click)="clearStatus($event)">
              Clear
            </button>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="test-status-select mobile-single-filter-select">
          <li
            *ngFor="let status of buildingDataFilterService.siteStatusOptions"
            [class]="'test-status-option-' + (status.value || 'all')"
            [class.checked]="status.value === buildingDataFilterService.statusFilter"
            (click)="buildingDataFilterService.statusFilter = status.value">
            <span>{{ status.title }}</span>
            <i class="icon-check text-ui-green-light text-3xl"></i>
          </li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div>
              Incidents
              <span
                *ngIf="buildingDataFilterService.incidentFilter.length"
                class="test-mobile-multiselect-filter-inicator mobile-multiselect-filter-inicator"
                >+{{ buildingDataFilterService.incidentFilter.length }}</span
              >
            </div>

            <button
              *ngIf="buildingDataFilterService.incidentFilter.length"
              class="test-clear-incident-filter mobile-filter-clear-btn"
              (click)="clearIncidents($event)">
              Clear
            </button>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="test-incident-status-filter">
          <li *ngFor="let incident of buildingDataFilterService.incidentOptions">
            <mat-checkbox
              class="full-width-checkbox"
              [class]="'test-incident-status-option-' + incident.value"
              [value]="incident.value"
              (change)="incidentChange($event)"
              [checked]="incident.checked">
              {{ incident.title }}
            </mat-checkbox>
          </li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
