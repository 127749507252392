<div *ngIf="user; else loading" class="test-user-wrapper">
  <div *ngIf="editor$ | async as editor">
    <header class="flex">
      <app-mobile-nested-page-header
        class="flex-grow"
        [title]="user.firstName + ' ' + user.lastName"
        titleName="Users Management:" />

      <div class="flex">
        <button
          appRestrictedEl
          [showForClientAdmin]="true"
          class="test-resend-invitation btn-blue btn-medium mr-3 header-btn relative"
          [disabled]="(resendInvitationLoading$ | async) === true"
          (click)="resendInvitation(user)"
          *ngIf="user.status === UserStatus.INVITED">
          <mat-spinner *ngIf="(resendInvitationLoading$ | async) === true" diameter="20" class="absolute" />
          <i *ngIf="(resendInvitationLoading$ | async) === false" class="icon-resend-email text-3xl action-icon"></i>
        </button>
        <app-mobile-mobile-buttons-bar
          *ngIf="editor.id !== user.id"
          [pauseEnabled]="false"
          [archiveEnabled]="true"
          [isArchived]="user.status === UserStatus.ARCHIVED"
          (archiveTrigger)="user.status === UserStatus.ARCHIVED ? restore(user) : archive(user)" />
      </div>
    </header>
    <app-user-details [isMobile]="true" [editor]="editor" [user]="user" />
  </div>
</div>

<ng-template #loading>
  <app-mobile-space-skeleton-animation class="test-skeleton-animation" />
</ng-template>
