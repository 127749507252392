import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MapFilterService } from '@services';

@Component({
  selector: 'app-mobile-map-filters-sheet',
  templateUrl: './map-filters.sheet.component.html',
})
export class MapFiltersSheetComponent {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<MapFiltersSheetComponent>,
    public mapFilterService: MapFilterService
  ) {}

  close() {
    this._bottomSheetRef.dismiss();
  }

  get selectedFilters() {
    return this.mapFilterService.mapFilter.length;
  }

  mapFilterChange(event: MatCheckboxChange) {
    this.mapFilterService.updateMapFilterOption({ value: event.source.value, checked: event.checked });
  }

  clearAll() {
    this.mapFilterService.mapFilter = [];
  }
}
