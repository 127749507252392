import { DestroyRef, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TippyInstance } from '@ngneat/helipopper';
import { AppService } from '@services';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';
import { Subscription } from 'rxjs';

export abstract class AttachmentPopoverComponent {
  destroyRef = inject(DestroyRef);
  hide: () => void;
  subs$: Subscription | undefined;
  attachmentName = '';
  id = '';
  locationId = '';
  dialogTitle = '';
  dialogText = '';

  constructor(tippy: TippyInstance, private dialog: MatDialog, private appService: AppService) {
    const { attachmentName, id, dialogTitle, dialogText, locationId } = tippy.data;
    this.attachmentName = attachmentName;
    this.id = id;
    this.dialogTitle = dialogTitle;
    this.dialogText = dialogText;
    this.locationId = locationId;
    this.hide = tippy.hide;
    tippy.setProps({ arrow: false });
  }

  editAttachment() {
    this.appService.activeAttachmentId = this.id;
    this.hide();
  }

  abstract deleteAttachment(): void;

  openConfirmationDialog() {
    this.subs$ = ConfirmationDialogComponent.open(this.dialog, {
      title: this.dialogTitle,
      description: this.dialogText,
    }).subscribe(confirmation => {
      if (confirmation) {
        this.deleteAttachment();
      }
      this.subs$?.unsubscribe();
    });
  }
}
