<div class="flex items-center justify-between mb-2">
  <h1 class="mobile-page-main-title">Devices</h1>
  <button
    [disabled]="!(devicesFilterService.filteredDevices$ | async)?.length"
    class="test-open-export-devices-button btn-blue btn-medium w-10 h-10"
    (click)="openExportDevicesBottomSheet()">
    <i class="icon-upload-map text-3xl"></i>
  </button>
</div>
<div class="test-devices-table-wrapper" *ngIf="(deviceListLoaded$ | async) === true; else skeleton">
  <div class="mb-3 flex items-center">
    <app-search-input
      style="width: 100%"
      placeholder="Device Name, type & other"
      (searchDataEvent)="devicesFilterService.deviceSearch($event)"
      inputWidth="100%" />
    <button
      class="test-toggle-table-filter-btn toggle-table-filter-btn ml-3"
      [class.has-selected-filters]="devicesFilterService.selectedFilters"
      (click)="openDeviceFiltersBottomSheet()">
      <i class="icon-filter_Menu text-3xl"></i>
    </button>
  </div>
  <app-devices-table [isNoDataBigSize]="true" [isMobile]="true" />
</div>

<ng-template #skeleton>
  <app-skeleton-table class="test-skeleton-table-loader" [showTabs]="false" />
</ng-template>
