@if (locationData && currentRoom && currentFloor) { @let isLandscape = appService.screenOrientation ===
ScreenOrientations.LANDSCAPE;
<header class="flex">
  <app-mobile-nested-page-header
    class="flex-grow"
    [title]="currentRoom.friendlyName"
    [backHomeLink]="true"
    titleName="Room" />
  <app-mobile-mobile-buttons-bar
    [isPaused]="currentRoom.status === EntityStatus.Paused"
    [isArchived]="currentRoom.status === EntityStatus.Archived"
    [editEnabled]="true"
    (editTrigger)="openLinkEditModal()"
    [linkEnabled]="!!currentRoom.externalReference"
    (linkTrigger)="window.open(currentRoom.externalReference, '_blank')"
    (statusTrigger)="pauseRoom()" />
</header>

<app-mobile-building-address-bar
  [buildingAddress]="locationData.address.streetAddress + ', ' + locationData.address.city"
  [buildingName]="locationData.friendlyName"
  [floorStatus]="currentRoom.status" />

<div [class.flex]="isLandscape">
  <mat-card [class.mr-2]="isLandscape" class="flex-grow card divider-card mt-3">
    <div>
      <div>Floor:</div>
      <div>
        <strong class="underline cursor-pointer" appBackButton>{{ currentFloor.friendlyName }}</strong>
      </div>
    </div>
    <div class="divider">
      <div></div>
      <div class="line-block"></div>
      <div></div>
    </div>
    <div>
      <div>Room:</div>
      <div (click)="openRoomList()" class="false-dropdown">
        <div>
          <strong>{{ currentRoom.friendlyName }}</strong>
          <mat-icon class="ml-2" fontIcon="expand_more"></mat-icon>
        </div>
      </div>
    </div>
  </mat-card>
  <app-mobile-incident-info-block [class.ml-2]="isLandscape" class="flex-grow" [spaceStats]="roomStats" />
</div>

<div class="border-b-ui-gray-light border-b flex items-center justify-between mb-3 pt-4">
  <mat-button-toggle-group
    class="mobile-toggle-group"
    aria-label="Dashboard Tabs"
    [value]="tab"
    (change)="tab = $event.value"
    *ngIf="roomHasIncidents || roomHasDevices || roomHasFloorPlan">
    <mat-button-toggle *ngIf="roomHasDevices" value="devices">
      <span class="item-status-title">Devices</span>
    </mat-button-toggle>
    <mat-button-toggle value="floorPlan" *ngIf="roomHasFloorPlan">
      <span class="item-status-title">Floor Plan</span>
    </mat-button-toggle>
    <mat-button-toggle value="incidents" *ngIf="roomHasIncidents">
      <span class="item-status-title">Incidents</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
<div *ngIf="roomHasDevices" [class]="tab === Tabs.DEVICES ? 'visible' : 'is-hidden-tab'">
  <div class="mb-3 flex items-center">
    <app-search-input
      style="width: 100%"
      placeholder="Device Name, type & other"
      (searchDataEvent)="devicesFilterService.deviceSearch($event)"
      inputWidth="100%" />
    <button
      class="toggle-table-filter-btn ml-3"
      [class.has-selected-filters]="devicesFilterService.selectedFilters"
      (click)="openDeviceFiltersBottomSheet()">
      <i class="icon-filter_Menu text-3xl"></i>
    </button>
  </div>
  <app-devices-table [isNoDataBigSize]="true" [isMobile]="true" />
</div>
<div *ngIf="roomHasFloorPlan && roomId" [class]="tab === Tabs.FLOOR_PLAN ? 'visible' : 'is-hidden-tab'">
  <app-mobile-floorplan-tab [currentFloor]="currentFloor" [locationId]="locationId" [roomId]="roomId" class="block" />
</div>
<div *ngIf="roomHasIncidents" [class]="tab === Tabs.INCIDENTS ? 'visible' : 'is-hidden-tab'">
  <app-mobile-incidents-tab [locationId]="locationId" [floorId]="floorId" [roomId]="currentRoom.id" />
</div>
} @else {
<app-mobile-space-skeleton-animation />
}
