import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SignInContainerComponent } from './containers/sign-in-container/sign-in-container.component';
import { ForgotPasswordContainerComponent } from './containers/forgot-password-container/forgot-password-container.component';
import { PasswordRecoveryContainerComponent } from './containers/password-recovery-container/password-recovery-container.component';
import { SignInComponent } from '@standalone/sign-in/sign-in.component';
import { ForgotPasswordComponent } from '@standalone/forgot-password/forgot-password.component';
import { PasswordRecoveryComponent } from '@standalone/password-recovery/password-recovery.component';

@NgModule({
  declarations: [SignInContainerComponent, ForgotPasswordContainerComponent, PasswordRecoveryContainerComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: SignInContainerComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordContainerComponent,
      },
      {
        path: 'forgot-password/:state',
        component: ForgotPasswordContainerComponent,
      },
      {
        path: 'password-recovery',
        component: PasswordRecoveryContainerComponent,
      },
      {
        path: 'password-recovery/:state',
        component: PasswordRecoveryContainerComponent,
      },
    ]),
    SignInComponent,
    ForgotPasswordComponent,
    PasswordRecoveryComponent,
  ],
})
export class AuthModule {}
