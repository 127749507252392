import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ExportDevicesService } from '@services';

@Component({
  selector: 'app-mobile-export-devices-sheet',
  templateUrl: './export-devices-sheet.component.html',
})
export class ExportDevicesSheetComponent {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ExportDevicesSheetComponent>,
    private exportDevicesService: ExportDevicesService
  ) {}

  close() {
    this._bottomSheetRef.dismiss();
  }

  exportDevices(value: 'csv' | 'json') {
    this.exportDevicesService.emitExportType(value);
  }
}
