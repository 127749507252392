import { LocationData, LocationFullData, RoomSpaceModel } from '@models';
import { createReducer, on } from '@ngrx/store';
import {
  loadLocations,
  loadLocationsSummaries,
  loadLocationsSummariesError,
  loadLocationsSummariesSuccess,
  locationsLoaded,
} from './locations.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { LocationActions } from '../action-types';

export interface LocationsState extends EntityState<LocationData> {
  allLocationsLoaded: boolean;
  allLocationsLoading: boolean;
  isFullLocationLoaded: boolean;
  isLocationLoading: boolean;
  locationError: any;
  locationState: LocationFullData;
  floorLoading: boolean;
  floorError: any;
  roomLoading: boolean;
  roomError: any;
  floorplanLoading: boolean;
  floorplanError: any;
  roomplanLoading: boolean;
  roomplanError: any;
  floorUpdateLoading: boolean;
  roomUpdateLoading: boolean;
  isLocationsSummariesLoading: boolean;
}

export const locationAdapter = createEntityAdapter<LocationData>({});

export const initialLocationsState = locationAdapter.getInitialState({
  allLocationsLoaded: false,
  allLocationsLoading: false,
  isLocationLoading: false,
  isFullLocationLoaded: false,
  locationError: null,
  floorLoading: false,
  floorError: null,
  roomLoading: false,
  locationState: {} as LocationFullData,
  roomError: null,
  floorplanLoading: false,
  floorplanError: null,
  roomplanLoading: false,
  roomplanError: null,
  floorUpdateLoading: false,
  roomUpdateLoading: false,
  isLocationsSummariesLoading: false,
});

export const locationsReducer = createReducer(
  initialLocationsState,
  on(loadLocations, state => {
    return {
      ...state,
      allLocationsLoading: false,
    };
  }),
  on(locationsLoaded, (state, action) => {
    return locationAdapter.setAll(action.location, {
      ...state,
      allLocationsLoaded: true,
      allLocationsLoading: false,
      locationState: {} as LocationFullData,
    });
  }),
  on(loadLocationsSummaries, state => {
    return { ...state, isLocationsSummariesLoading: true };
  }),
  on(loadLocationsSummariesSuccess, (state, action) => {
    return locationAdapter.updateMany(action.data, {
      ...state,
      isLocationsSummariesLoading: false,
    });
  }),
  on(loadLocationsSummariesError, state => {
    return { ...state, isLocationsSummariesLoading: false };
  }),
  on(LocationActions.addLocation, state => ({ ...state, isLocationLoading: true, locationError: null })),
  on(LocationActions.addLocationSuccess, (state, action) =>
    locationAdapter.addOne(action.newLocation, {
      ...state,
      isLocationLoading: false,
      locationState: { ...action.newLocation, floors: [] },
    })
  ),
  on(LocationActions.loadLocationError, (state, action) => ({
    ...state,
    isLocationLoading: false,
    locationError: action.locationError,
  })),

  on(LocationActions.updateLocation, state => ({ ...state, isLocationLoading: true, locationError: null })),
  on(LocationActions.locationError, (state, action) => ({
    ...state,
    isLocationLoading: false,
    locationError: action.locationError,
  })),
  on(LocationActions.updateLocationSuccess, (state, action) =>
    locationAdapter.updateOne(
      {
        id: action.location.id,
        changes: action.location,
      },
      {
        ...state,
        isLocationLoading: false,
        locationState: Object.keys(state.locationState).length
          ? { ...action.location, floors: [...state.locationState.floors] }
          : ({} as LocationFullData),
      }
    )
  ),
  on(LocationActions.getLocationState, state => ({
    ...state,
    isLocationLoading: true,
    isFullLocationLoaded: false,
  })),
  on(LocationActions.getLocationStateSuccess, (state, action) => {
    return {
      ...state,
      locationState: action.locationState,
      isLocationLoading: false,
      isFullLocationLoaded: true,
    };
  }),
  on(LocationActions.resetLocationState, state => {
    return {
      ...state,
      locationState: {} as LocationFullData,
    };
  }),
  on(LocationActions.resetDefaultLocationsState, () => {
    return {
      ...initialLocationsState,
    };
  }),
  // Handle Floor actions
  on(LocationActions.updateFloor, state => {
    return {
      ...state,
      floorUpdateLoading: true,
      floorError: null,
    };
  }),
  on(LocationActions.updateFloorSuccess, (state, action) => {
    return {
      ...state,
      floorUpdateLoading: false,
      floorError: null,
      locationState: {
        ...state.locationState,
        floors: state.locationState.floors.map(floor => {
          return floor.id === action.floor.id ? { ...floor, ...action.floor } : floor;
        }),
      },
    };
  }),
  on(LocationActions.updateFloorError, (state, action) => {
    return {
      ...state,
      floorUpdateLoading: false,
      floorError: action.error,
    };
  }),
  on(LocationActions.addFloor, state => {
    return {
      ...state,
      floorLoading: true,
      floorError: null,
    };
  }),
  on(LocationActions.addFloorSuccess, (state, action) => {
    return {
      ...state,
      floorLoading: false,
      floorError: null,
      locationState: { ...state.locationState, floors: [...state.locationState.floors, action.floor] },
    };
  }),
  on(LocationActions.addFloorError, (state, action) => {
    return {
      ...state,
      floorLoading: false,
      floorError: action.error,
    };
  }),
  // Handle Room actions
  on(LocationActions.addRoom, state => {
    return {
      ...state,
      roomLoading: true,
      roomError: null,
    };
  }),
  on(LocationActions.addRoomSuccess, (state, action) => {
    return {
      ...state,
      roomLoading: false,
      roomError: null,
      locationState: {
        ...state.locationState,
        floors: state.locationState.floors.map(floor => {
          if (floor.id === action.room.parentSpaceId) {
            return {
              ...floor,
              rooms: floor.rooms ? [...floor.rooms, action.room] : [action.room],
            };
          }
          return floor;
        }),
      },
    };
  }),
  on(LocationActions.addRoomError, (state, action) => {
    return {
      ...state,
      roomLoading: false,
      roomError: action.error,
    };
  }),
  on(LocationActions.updateRoom, state => {
    return {
      ...state,
      roomUpdateLoading: true,
      roomError: null,
    };
  }),
  on(LocationActions.updateRoomSuccess, (state, action) => {
    return {
      ...state,
      roomUpdateLoading: false,
      roomError: null,
      locationState: {
        ...state.locationState,
        floors: state.locationState.floors.map(floor => {
          if (floor.id === action.room.parentSpaceId) {
            return {
              ...floor,
              rooms: floor.rooms
                ? floor.rooms.map((room: RoomSpaceModel) =>
                    room.id === action.room.id ? { ...room, ...action.room } : room
                  )
                : [],
            };
          }
          return floor;
        }),
      },
    };
  }),
  on(LocationActions.updateRoomError, (state, action) => {
    return {
      ...state,
      roomUpdateLoading: false,
      roomError: action.error,
    };
  }),
  on(LocationActions.uploadRoomplan, state => {
    return {
      ...state,
      roomplanLoading: true,
      roomplanError: null,
    };
  }),
  on(LocationActions.uploadRoomplanSuccess, (state, action) => {
    return {
      ...state,
      roomplanLoading: false,
      locationState: {
        ...state.locationState,
        floors: state.locationState.floors.map(floor => {
          if (floor.id === action.floorId) {
            return {
              ...floor,
              rooms: floor.rooms
                ? floor.rooms.map((room: RoomSpaceModel) =>
                    room.id === action.roomId ? { ...room, documentReference: action.document.fileName } : room
                  )
                : [],
            };
          }
          return floor;
        }),
      },
    };
  }),
  on(LocationActions.uploadRoomplanError, (state, action) => {
    return {
      ...state,
      roomplanLoading: false,
      roomplanError: action.error,
    };
  }),
  on(LocationActions.deleteRoomplan, state => {
    return {
      ...state,
      roomplanLoading: true,
      roomplanError: null,
    };
  }),
  on(LocationActions.deleteRoomplanSuccess, (state, action) => {
    return {
      ...state,
      roomplanLoading: false,
      locationState: {
        ...state.locationState,
        floors: state.locationState.floors.map(floor => {
          if (floor.id === action.floorId) {
            return {
              ...floor,
              rooms: floor.rooms
                ? floor.rooms.map((room: RoomSpaceModel) =>
                    room.id === action.roomId ? { ...room, documentReference: '' } : room
                  )
                : [],
            };
          }
          return floor;
        }),
      },
    };
  }),
  on(LocationActions.deleteRoomplanError, (state, action) => {
    return {
      ...state,
      roomplanLoading: false,
      roomplanError: action.error,
    };
  }),
  // Floorpan actions
  on(LocationActions.uploadFloorplan, state => {
    return {
      ...state,
      floorplanLoading: true,
      floorplanError: null,
    };
  }),
  on(LocationActions.uploadFloorplanSuccess, (state, action) => {
    return {
      ...state,
      floorplanLoading: false,
      locationState: {
        ...state.locationState,
        floors: state.locationState.floors.map(floor => {
          return floor.id === action.spaceId ? { ...floor, documentReference: action.document.fileName } : floor;
        }),
      },
    };
  }),
  on(LocationActions.uploadFloorplanError, (state, action) => {
    return {
      ...state,
      floorplanLoading: false,
      floorplanError: action.error,
    };
  }),
  on(LocationActions.deleteFloorplan, state => {
    return {
      ...state,
      floorplanLoading: true,
      floorplanError: null,
    };
  }),
  on(LocationActions.deleteFloorplanSuccess, (state, action) => {
    return {
      ...state,
      floorplanLoading: false,
      locationState: {
        ...state.locationState,
        floors: state.locationState.floors.map(floor => {
          return floor.id === action.spaceId ? { ...floor, documentReference: '' } : floor;
        }),
      },
    };
  }),
  on(LocationActions.deleteFloorplanError, (state, action) => {
    return {
      ...state,
      floorplanLoading: false,
      floorplanError: action.error,
    };
  })
);
