import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BuildingDataFilterService } from '@services';

@Component({
  selector: 'app-mobile-building-filters-sheet',
  templateUrl: './building-filters-sheet.component.html',
})
export class BuildingFiltersSheetComponent {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BuildingFiltersSheetComponent>,
    public buildingDataFilterService: BuildingDataFilterService
  ) {}

  close() {
    this._bottomSheetRef.dismiss();
  }

  incidentChange(event: MatCheckboxChange) {
    this.buildingDataFilterService.updateIncidentFilter({ value: event.source.value, checked: event.checked });
  }

  clearIncidents(event: Event) {
    event.stopPropagation();
    this.buildingDataFilterService.incidentFilter = [];
  }

  clearStatus(event: Event) {
    event.stopPropagation();
    this.buildingDataFilterService.statusFilter = '';
  }

  get selectedFilters() {
    return !!(this.buildingDataFilterService.statusFilter || this.buildingDataFilterService.incidentFilter?.length);
  }

  clearAll() {
    this.buildingDataFilterService.incidentFilter = [];
    this.buildingDataFilterService.statusFilter = '';
  }
}
