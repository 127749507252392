import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-bottom-sheet-mobile-header',
  standalone: true,
  template: ` <div class="relative flex justify-end px-2">
    <span class="absolute t-0 left-1/2 transform -translate-x-1/2 w-[55px] h-2 rounded-full bg-ui-gray-650"></span>
    <button (click)="closeEvent.emit('close')" class="flex items-center justify-center w-9 h-9">
      <i class="icon-close text-ui-gray-700 text-[28px]"></i>
    </button>
  </div>`,
})
export class BottomSheetMobileHeaderComponent {
  @Output() closeEvent = new EventEmitter();
}
