import { Component, Input } from '@angular/core';
import { EntityStatus } from '@models';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { DeviceStatusCountComponent } from '@standalone/device-status-count/device-status-count.component';

@Component({
  standalone: true,
  selector: 'app-space-card-info',
  templateUrl: './space-card-info.component.html',
  styles: [],
  imports: [NgIf, RouterLink, DeviceStatusCountComponent],
})
export class SpaceCardInfoComponent {
  @Input() status: EntityStatus = EntityStatus.Active;
  @Input() incidentNew = 0;
  @Input() incidentInProgress = 0;
  @Input() spaceLink: string[] = [];
  @Input() name = '';
  @Input() address = '';
  @Input() deviceActive = 0;
  @Input() devicePaused = 0;

  entityStatus = EntityStatus;
}
