import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { IncidentTabStatusFilter } from '@models';
import { IncidentsFilterService } from '@services';

@Component({
  selector: 'app-mobile-incidents-filters-sheet',
  templateUrl: './incident-filters-sheet.component.html',
})
export class IncidentFiltersSheetComponent {
  incidentTabStatusFilterEnum = IncidentTabStatusFilter;
  step = 0;
  stepResolved = 0;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<IncidentFiltersSheetComponent>,
    public incidentsFilterService: IncidentsFilterService
  ) {}

  close() {
    this._bottomSheetRef.dismiss();
  }

  clearDeviceTypeFilter(event: Event) {
    event.stopPropagation();
    this.incidentsFilterService.deviceTypeValue = [];
  }

  clearIncidentTypeFilter(event: Event) {
    event.stopPropagation();
    this.incidentsFilterService.statusFilter = [];
  }

  clearSelectedBuilding(event: Event, step: number) {
    event.stopPropagation();
    this.incidentsFilterService.selectedBuilding = '';
    this.handleOpenIncidentsExpansionPanelsOnClear(step);
  }

  clearSelectedFloor(event: Event, step: number) {
    event.stopPropagation();
    this.incidentsFilterService.selectedFloor = '';
    this.handleOpenIncidentsExpansionPanelsOnClear(step);
  }

  clearSelectedRoom(event: Event) {
    event.stopPropagation();
    this.incidentsFilterService.selectedRoom = '';
  }

  handleOpenIncidentsExpansionPanelsOnClear(step: number) {
    if (this.step === 0) {
      return;
    }

    if (this.step > step) {
      this.step = 0;
    }
  }

  clearResolvedIncidentsDeviceTypeFilter(event: Event) {
    event.stopPropagation();
    this.incidentsFilterService.resolvedIncidentsDeviceTypeValue = [];
  }

  clearResolvedIncidentsSelectedBuilding(event: Event, step: number) {
    event.stopPropagation();
    this.incidentsFilterService.resolvedIncidentsSelectedBuilding = '';
    this.handleResolvedIncidentsExpansionPanelsOnClear(step);
  }

  clearResolvedIncidentsSelectedFloor(event: Event, step: number) {
    event.stopPropagation();
    this.incidentsFilterService.resolvedIncidentsSelectedFloor = '';
    this.handleResolvedIncidentsExpansionPanelsOnClear(step);
  }

  clearResolvedIncidentsSelectedRoom(event: Event) {
    event.stopPropagation();
    this.incidentsFilterService.resolvedIncidentsSelectedRoom = '';
  }

  handleResolvedIncidentsExpansionPanelsOnClear(step: number) {
    if (this.stepResolved === 0) {
      return;
    }

    if (this.stepResolved > step) {
      this.stepResolved = 0;
    }
  }
}
