import { Component } from '@angular/core';
import { UserDetailsClass } from '@abstraction';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@ngrx-store';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-mobile-user-details',
  templateUrl: './user-details.component.html',
  styles: '',
})
export class MobileUserDetailsPageComponent extends UserDetailsClass {
  constructor(override route: ActivatedRoute, override store: Store<AppState>, override dialog: MatDialog) {
    super(route, store, dialog);
  }
}
