import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, loadAllClientDevices, loadDeviceDetails } from '@ngrx-store';

export const deviceResolver: ResolveFn<void> = (route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
  return inject(Store<AppState>).dispatch(loadAllClientDevices());
};

export const deviceDetailsResolver: ResolveFn<void> = (route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
  const locId = route.paramMap.get('locationId') || '';
  const devId = route.paramMap.get('deviceId') || '';
  return inject(Store<AppState>).dispatch(loadDeviceDetails({ locationId: locId, deviceId: devId }));
};
