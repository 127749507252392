import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ClientSelectMobileComponent } from '@standalone/client-select-mobile/client-select-mobile.component';

@Component({
  selector: 'app-mobile-mobile-main-header',
  standalone: true,
  imports: [RouterLink, ClientSelectMobileComponent],
  styles: [],
  template: ` <header class="flex justify-between bg-white p-4">
    <div class="w-[180px] pt-1">
      <img src="assets/logo_green.png" alt="logo" />
    </div>
    <div class="flex items-center">
      <app-mobile-client-select />
      <a
        routerLink="/user-profile"
        class="w-[40px] h-[40px] bg-ui-gray-light rounded-full flex items-center justify-evenly ml-2">
        <i class="icon-my-profile-stroke text-3xl text-black"></i>
      </a>
    </div>
  </header>`,
})
export class MobileMainHeaderComponent {}
