<app-bottom-sheet-mobile-header (closeEvent)="close()" />
<div class="mx-6 my-3 flex justify-between items-center">
  <h2 class="sheet-title">Map Alerts</h2>
  <button
    *ngIf="selectedFilters"
    class="test-mobile-filter-clear-all-btn mobile-filter-clear-all-btn"
    (click)="clearAll()">
    Clear All
  </button>
</div>
<ul class="mx-4 my-3 building-select-options test-map-filter-select">
  <li *ngFor="let filter of mapFilterService.mapFilterOptions">
    <mat-checkbox
      [class]="'test-map-option-' + filter.value"
      class="full-width-checkbox"
      [value]="filter.value"
      (change)="mapFilterChange($event)"
      [checked]="filter.checked">
      {{ filter.title }}
    </mat-checkbox>
  </li>
</ul>
