import { createReducer, on } from '@ngrx/store';
import { AuthorizedUser, UserRolesTypes } from '@models';
import {
  authActionError,
  authChangeUserPassword,
  authUserPasswordChanged,
  forgotPassword,
  forgotPasswordSuccess,
  login,
  logout,
  passwordRecovered,
  passwordRecovery,
  superAdminClientCreated,
  updatePersonalDataSuccess,
} from './auth.actions';
import { immerOn } from 'ngrx-immer/store';

export interface UserState extends AuthorizedUser {
  isLoading?: boolean;
}

export const initialUserState: UserState = {
  email: '',
  firstName: '',
  id: '',
  lastActivity: '',
  lastName: '',
  organizationName: '',
  phone: {
    countryCode: '',
    number: '',
    countryNumberCode: '',
    smsOptIn: false,
  },
  role: {
    id: '',
    name: UserRolesTypes.Empty,
  },
  status: '',
  isLoading: false,
  notificationsPreferences: {
    emailEnabled: false,
    smsEnabled: false,
  },
  homeClientUserSettings: {
    userSessionTimeoutMinutes: 10,
  },
};

export const authReducer = createReducer(
  initialUserState,

  on(login, (state, action) => {
    return action.user;
  }),

  on(logout, () => {
    return initialUserState;
  }),

  on(forgotPassword, state => {
    return { ...state, isLoading: true };
  }),

  on(passwordRecovery, state => {
    return { ...state, isLoading: true };
  }),

  on(passwordRecovered, state => {
    return { ...state, isLoading: false };
  }),

  on(forgotPasswordSuccess, state => {
    return { ...state, isLoading: false };
  }),

  on(authActionError, state => {
    return { ...state, isLoading: false };
  }),

  on(authChangeUserPassword, state => {
    return { ...state, isLoading: true };
  }),

  on(authUserPasswordChanged, state => {
    return { ...state, isLoading: false };
  }),

  on(updatePersonalDataSuccess, (state, action) => {
    return { ...state, ...action.user };
  }),

  immerOn(superAdminClientCreated, (state, action) => {
    let clients = state.userClients;
    if (clients) {
      clients = [...clients, action.newClient];
    }
    return { ...state, ...{ userClients: clients } };
  })
);
