<div class="min-h-full flex max-h-full" *ngIf="isLoggedIn; else loggedOut">
  <main
    class="flex flex-grow w-full max-w-full max-h-full overflow-y-auto"
    [ngClass]="appService.screenOrientation === 'landscape' ? '' : 'flex-col'"
    [class.bg-ui-gray-200]="!isErrorPage">
    @if (appService.screenOrientation === ScreenOrientation.LANDSCAPE) {
    <app-mobile-main-menu *ngIf="showMenu" [orientation]="appService.screenOrientation" />
    }
    <div class="flex-grow flex flex-col overflow-y-hidden max-h-full">
      <app-mobile-mobile-main-header *ngIf="showMenu" />
      <section #section class="pb-6 px-4 pt-4 w-full flex-grow overflow-y-auto h-full max-h-full" cdkScrollable>
        <router-outlet />
      </section>
    </div>

    @if (appService.screenOrientation === ScreenOrientation.PORTRAIT) {
    <app-mobile-main-menu *ngIf="showMenu" [orientation]="appService.screenOrientation" />
    }
  </main>
</div>
<ng-template #loggedOut>
  <div class="h-screen overflow-y-auto flex flex-col">
    <div class="flex-1">
      <router-outlet />
    </div>
    <app-copyright-footer [isMobile]="true" />
  </div>
</ng-template>
