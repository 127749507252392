<div class="main-menu-wrapper px-4 bg-white" [class.landscape]="orientation === ScreenOrientations.LANDSCAPE">
  <ul class="mobile-menu">
    <li
      *ngFor="let menuItem of menuItems"
      routerLinkActive="main-menu-item-active"
      [routerLink]="menuItem.url ? menuItem.url : undefined"
      #rla="routerLinkActive">
      <i
        [ngClass]="rla.isActive ? menuItem.icon : menuItem.icon + '-stroke'"
        [class.menu-incident-indicator]="
          menuItem.showIncidentsAmount && ((inProgressIncidentsAmount$ | async) || (newIncidentsAmount$ | async))
        "></i>
      <div>{{ menuItem.title }}</div>
    </li>
    <li (click)="logout($event)">
      <i class="icon-log-out text-3xl text-ui-dark mx-auto"></i>
      <div>Log Out</div>
    </li>
  </ul>
</div>
