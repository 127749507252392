<h1 class="mobile-page-main-title">Incidents</h1>

<div *ngIf="(isIncidentLoaded$ | async) === true; else skeleton" class="test-incidents-table-wrapper">
  <div class="mb-3 flex items-center">
    <mat-button-toggle-group
      [value]="incidentsFilterService.incidentTabStatusFilter"
      class="mobile-main-toggle-group"
      name="Incident status"
      (change)="incidentsFilterService.incidentTabStatusFilter = $event.value">
      <mat-button-toggle
        [value]="incidentsFilterService.incidentTabStatusFilterEnum.OPEN"
        class="test-open-incidents-tab">
        Open
        <span *ngIf="incidentsFilterService.newIncidentsAmount" class="incidents-amount-indicator ml-2">{{
          incidentsFilterService.newIncidentsAmount
        }}</span>
        <span
          *ngIf="incidentsFilterService.inProgressIncidentsAmount"
          class="incidents-amount-indicator bg-ui-yellow-100 text-ui-yellow-350 ml-2"
          >{{ incidentsFilterService.inProgressIncidentsAmount }}</span
        >
      </mat-button-toggle>
      <mat-button-toggle
        [value]="incidentsFilterService.incidentTabStatusFilterEnum.HISTORY"
        class="test-history-incidents-tab">
        History
      </mat-button-toggle>
    </mat-button-toggle-group>
    <mat-spinner
      *ngIf="incidentsFilterService.resolvedIncidentsLoading"
      diameter="40"
      class="ml-1 test-resolved-incidents-spinner"></mat-spinner>
  </div>

  <div class="mb-3 flex items-center">
    <app-search-input
      style="width: 100%"
      placeholder="Enter Ticket №"
      (searchDataEvent)="incidentsFilterService.ticketNumberSearch($event)"
      inputWidth="100%" />
    <button
      class="test-open-incidents-bottom-sheet toggle-table-filter-btn ml-3"
      [class.has-selected-filters]="
        incidentsFilterService.incidentTabStatusFilter === incidentTabStatusFilterEnum.OPEN
          ? incidentsFilterService.selectedFilters
          : incidentsFilterService.resolvedIncidentsSelectedFilters
      "
      (click)="openIncidentFiltersBottomSheet()">
      <i class="icon-filter_Menu text-3xl"></i>
    </button>
  </div>

  <app-incidents-table noDataText="All devices are working properly" [isNoDataBigSize]="true" [isMobile]="true" />
</div>

<ng-template #skeleton>
  <app-skeleton-table class="test-skeleton-table-loader" [showTabs]="false" />
</ng-template>
