import { Component, Inject, OnInit } from '@angular/core';
import { SpaceDetailsClass } from '@abstraction';
import {
  DeviceData,
  DeviceTableCols,
  EntityStatus,
  FloorSpaceModel,
  LocationFullData,
  RoomSpaceModel,
  ScreenOrientations,
  SpaceStats,
} from '@models';
import { Store } from '@ngrx/store';
import { AppState, getDeviceBySpaceId, loadDeviceCollectionBySpaceId, updateRoom } from '@ngrx-store';
import { MatDialog } from '@angular/material/dialog';
import { AppService, DevicesFilterService } from '@services';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PipesModule } from '@pipes';
import { NgForOf, NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatListItem, MatListItemIcon, MatListItemTitle, MatNavList } from '@angular/material/list';
import { rooms } from '@mocks';
import { DeviceFiltersSheetComponent } from '@standalone/device-filters-sheet.component/device-filters-sheet.component';
import { map, Observable, of } from 'rxjs';
import { AddExternalLinkModalComponent } from '@standalone/_modals/add-external-link-modal.component/add-external-link-modal.component';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { BottomSheetMobileHeaderComponent } from '@standalone/bottom-sheet-mobile-header/bottom-sheet-mobile-header.component';

enum Tabs {
  DEVICES = 'devices',
  FLOOR_PLAN = 'floorPlan',
  INCIDENTS = 'incidents',
}

@Component({
  selector: 'app-mobile-room-details',
  templateUrl: './room-details.component.html',
  styleUrl: './room-details.component.scss',
})
export class RoomDetailsComponent extends SpaceDetailsClass implements OnInit {
  protected readonly Tabs = Tabs;
  currentFloor!: FloorSpaceModel | undefined;
  currentRoom!: RoomSpaceModel | undefined;
  roomStats!: SpaceStats;
  roomHasFloorPlan = false;
  roomHasIncidents = false;
  roomHasDevices = false;
  tab: Tabs = Tabs.DEVICES;

  constructor(
    public override store: Store<AppState>,
    public override dialog: MatDialog,
    public override appService: AppService,
    public override route: ActivatedRoute,
    public devicesFilterService: DevicesFilterService,
    private bottomSheet: MatBottomSheet
  ) {
    super(store, dialog, appService, route);
    this.routerWatcher();
    this.onLocationLoaded = () => {
      this.devicesFilterService.initColumns([
        DeviceTableCols.DEVICE_NAME,
        DeviceTableCols.MANUFACTURER,
        DeviceTableCols.MODEL,
        DeviceTableCols.SERIAL_NUMBER,
        DeviceTableCols.DEVICE_TYPE,
        DeviceTableCols.STATUS,
        DeviceTableCols.INCIDENTS,
      ]);
      this.currentFloor = this.locationData.floors.find(floor => floor.id === this.floorId);
      this.updateFloorRoomData();
    };
  }

  ngOnInit() {
    this.getStoreData();
    this.devicesFilterService.showLocationFilter = false;
  }

  routerWatcher() {
    this.route.params.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(params => {
      this.roomId = params['roomId'];
      this.floorId = params['floorId'];
      this.updateFloorRoomData();
    });
  }

  updateFloorRoomData() {
    if (!this.locationData?.floors) return;
    if (this.currentFloor?.rooms) {
      this.currentRoom = this.currentFloor?.rooms.find(room => room.id === this.roomId);
      this.findAndObserveRoomDevices().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
      this.updateRoomStats();
      this.roomHasFloorPlan = !!(this.currentFloor.documentReference && this.currentRoom?.unstructuredDataReference);
      this.roomHasIncidents = !!(this.roomStats.incidentAlert || this.roomStats.incidentInProgress);
      this.roomHasDevices = !!(this.roomStats.deviceActive || this.roomStats.devicePaused);
      this.checkTabAvailability();
    }
  }

  private checkTabAvailability() {
    const tabChecks: { [key: string]: boolean } = {
      [Tabs.DEVICES]: this.roomHasDevices,
      [Tabs.FLOOR_PLAN]: this.roomHasFloorPlan,
      [Tabs.INCIDENTS]: this.roomHasIncidents,
    };
    for (const tab in tabChecks) {
      if (tabChecks[tab]) {
        this.tab = tab as Tabs;
        break;
      }
    }
  }

  private findAndObserveRoomDevices(): Observable<DeviceData[]> {
    if (!this.locationData?.floors) {
      return of([]);
    }

    if (this.currentFloor?.rooms) {
      this.currentRoom = this.currentFloor?.rooms.find(room => room.id === this.roomId);

      if (this.currentRoom) {
        this.store.dispatch(
          loadDeviceCollectionBySpaceId({
            locationId: this.locationId,
            roomId: this.currentRoom.id,
          })
        );

        this.initDeviceFilterServiceColumns();
        return this.store.select(getDeviceBySpaceId(this.currentRoom.id)).pipe(
          map(devices => {
            this.devicesFilterService.initDevices(devices);
            this.devicesFilterService.showLocationFilter = false;
            return devices;
          })
        );
      }
      this.updateRoomStats();
    }
    return of([]);
  }

  private initDeviceFilterServiceColumns() {
    const columns = [
      DeviceTableCols.MANUFACTURER,
      DeviceTableCols.MODEL,
      DeviceTableCols.SERIAL_NUMBER,
      DeviceTableCols.DEVICE_TYPE,
      DeviceTableCols.STATUS,
      DeviceTableCols.INCIDENTS,
    ];
    this.devicesFilterService.initColumns(columns);
  }

  private updateRoomStats() {
    this.roomStats = {
      incidentAlert: this.currentRoom?.incidentCountByStatuses.newCount || 0,
      incidentInProgress: this.currentRoom?.incidentCountByStatuses.inProgressCount || 0,
      deviceActive: this.currentRoom?.devicesByStatuses.activeCount || 0,
      devicePaused: this.currentRoom?.devicesByStatuses.pausedCount || 0,
      rooms: 0,
      floors: 0,
    };
  }

  pauseRoom() {
    const isActive = this.currentRoom?.status !== EntityStatus.Paused;
    const title = isActive ? 'Pause' : 'Resume';
    const description = `Are you sure you want to ${isActive ? 'pause' : 'resume'} the ${
      this.currentRoom?.friendlyName
    }?`;
    const status = isActive ? EntityStatus.Paused : EntityStatus.Active;
    if (this.currentRoom?.id) {
      this.toggleRoomStatus(title, description, status, this.currentRoom.id);
    }
  }

  private toggleRoomStatus(title: string, description: string, status: EntityStatus, spaceId: string) {
    this.openConfirmationDialog({
      title,
      description,
      action: updateRoom({
        spaceId,
        locationId: this.locationData.id,
        data: { status },
      }),
    });
  }

  openRoomList() {
    this.bottomSheet.open(RoomListSheetComponent, {
      data: {
        locationData: this.locationData,
        currentFloor: this.currentFloor,
        roomId: this.roomId,
      },
    });
  }

  openDeviceFiltersBottomSheet() {
    this.bottomSheet.open(DeviceFiltersSheetComponent);
  }

  protected readonly window = window;

  openLinkEditModal() {
    this.dialog.open(AddExternalLinkModalComponent, {
      data: {
        locationId: this.locationId,
        link: this.currentRoom?.externalReference,
        spaceId: this.currentRoom?.id,
      },
    });
  }

  protected readonly ScreenOrientations = ScreenOrientations;
}

@Component({
  selector: 'app-mobile-room-list',
  templateUrl: 'room-list-sheet.html',
  standalone: true,
  imports: [
    PipesModule,
    NgForOf,
    MatIcon,
    MatListItem,
    MatListItemIcon,
    MatListItemTitle,
    MatNavList,
    RouterLink,
    NgIf,
    SearchInputComponent,
    BottomSheetMobileHeaderComponent,
  ],
})
export class RoomListSheetComponent {
  protected readonly rooms = rooms;
  search = '';

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<RoomListSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      locationData: LocationFullData;
      currentFloor: FloorSpaceModel;
      roomId: string;
    }
  ) {}

  close() {
    this._bottomSheetRef.dismiss();
  }

  openRoom() {
    this.close();
  }
}
