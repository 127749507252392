import { devicesAdapter, DevicesState } from './devices.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const { selectAll } = devicesAdapter.getSelectors();

export const selectDevicesState = createFeatureSelector<DevicesState>('devices');

export const selectAllDevices = createSelector(selectDevicesState, selectAll);

export const deviceLoaded = createSelector(selectDevicesState, state => state.allDevicesLoaded);
export const spaceDeviceIsLoading = createSelector(selectDevicesState, state => state.spaceDeviceIsLoading);

export const getDeviceBySpaceId = (roomId: string) =>
  createSelector(selectAllDevices, state => {
    return state.filter(device => (device?.spacePath?.[1]?.id || device?.parentSpaceId) === roomId);
  });

export const getDeviceById = (deviceId: string) =>
  createSelector(selectAllDevices, state => state.find(device => device.id === deviceId));

export const getDeviceManufacturers = createSelector(selectDevicesState, state => state.manufacturers);

export const getDeviceModels = createSelector(selectDevicesState, state => state.models);

export const getAttributes = createSelector(selectDevicesState, state => state.attributes);

export const getFullModel = createSelector(selectDevicesState, state => state.fullModel);

export const isDeviceCreating = createSelector(selectDevicesState, state => state.isDeviceCreating);

export const isDeviceUpdating = createSelector(selectDevicesState, state => state.isDeviceUpdating);

export const isManufacturerCreating = createSelector(selectDevicesState, state => state.isManufacturerCreating);

export const isDeviceModelCreating = createSelector(selectDevicesState, state => state.isDeviceModelCreating);
