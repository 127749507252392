<app-bottom-sheet-mobile-header (closeEvent)="close()" />
<div class="mobile-action-sheet">
  <div class="mx-6 my-3 flex justify-between items-center">
    <h2 class="sheet-title">Filters</h2>
    <button
      *ngIf="
        incidentsFilterService.incidentTabStatusFilter === incidentTabStatusFilterEnum.OPEN &&
        incidentsFilterService.selectedFilters
      "
      class="mobile-filter-clear-all-btn"
      (click)="incidentsFilterService.clearOpenIncidentsFilters()">
      Clear All
    </button>
    <button
      *ngIf="
        incidentsFilterService.incidentTabStatusFilter === incidentTabStatusFilterEnum.HISTORY &&
        incidentsFilterService.resolvedIncidentsSelectedFilters
      "
      class="mobile-filter-clear-all-btn"
      (click)="incidentsFilterService.clearResolvedIncidentsFilters()">
      Clear All
    </button>
  </div>
  <div class="mobile-action-sheet-content">
    <div
      *ngIf="incidentsFilterService.incidentTabStatusFilter === incidentTabStatusFilterEnum.OPEN"
      class="test-open-incdents-filters-wrapper">
      <mat-accordion class="bottom-sheet-accordion">
        <mat-expansion-panel [expanded]="step === 1" (opened)="step = 1">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div>
                Device Type
                <span
                  *ngIf="incidentsFilterService.deviceTypeValue.length"
                  class="test-mobile-multiselect-device-type-inicator mobile-multiselect-filter-inicator"
                  >+{{ incidentsFilterService.deviceTypeValue.length }}</span
                >
              </div>
              <button
                *ngIf="incidentsFilterService.deviceTypeValue.length"
                class="test-mobile-device-type-clear-btn mobile-filter-clear-btn"
                (click)="clearDeviceTypeFilter($event)">
                Clear
              </button>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="test-device-type-filter">
            <li *ngFor="let incident of incidentsFilterService.deviceTypeOptions | sort : 'asc' : 'title'">
              <mat-checkbox
                [class]="'test-device-type-option-' + incident.value"
                class="full-width-checkbox"
                [value]="incident.value"
                (change)="
                  incidentsFilterService.updateDeviceTypeValue({ value: $event.source.value, checked: $event.checked })
                "
                [checked]="incident.checked">
                {{ incident.title }}
              </mat-checkbox>
            </li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step === 2" (opened)="step = 2">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div>
                Incidents
                <span
                  *ngIf="incidentsFilterService.statusFilter.length"
                  class="test-mobile-multiselect-incident-inicator mobile-multiselect-filter-inicator"
                  >+{{ incidentsFilterService.statusFilter.length }}</span
                >
              </div>
              <button
                *ngIf="incidentsFilterService.statusFilter.length"
                class="test-mobile-incident-type-clear-btn mobile-filter-clear-btn"
                (click)="clearIncidentTypeFilter($event)">
                Clear
              </button>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="test-incident-type-filter">
            <li *ngFor="let incident of incidentsFilterService.statusOptions">
              <mat-checkbox
                [class]="'test-incident-type-option-' + incident.value"
                class="full-width-checkbox"
                [value]="incident.value"
                (change)="
                  incidentsFilterService.updateStatusFilter({ value: $event.source.value, checked: $event.checked })
                "
                [checked]="incident.checked">
                {{ incident.title }}
              </mat-checkbox>
            </li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel
          *ngIf="!incidentsFilterService.isBuildingTable"
          [expanded]="step === 3"
          (opened)="step = 3">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="mobile-single-select-title">
                Building @if (incidentsFilterService.selectedBuilding) { :
                <span class="test-building-selected-value font-normal">{{
                  incidentsFilterService.selectedBuilding
                }}</span>
                }
              </div>
              <button
                *ngIf="incidentsFilterService.selectedBuilding"
                class="test-building-filter-clear-btn mobile-filter-clear-btn"
                (click)="clearSelectedBuilding($event, 3)">
                Clear
              </button>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="test-building-select mobile-single-filter-select">
            <li
              *ngFor="let building of incidentsFilterService.buildingOptions | sort : 'asc' : 'title' : 'all'"
              [class]="'test-building-option-' + (building.value || 'all')"
              [class.checked]="building.value === incidentsFilterService.selectedBuilding"
              (click)="incidentsFilterService.selectedBuilding = building.value">
              <span>{{ building.title }}</span>
              <i class="icon-check text-ui-green-light text-3xl"></i>
            </li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel
          class="test-floor-panel"
          *ngIf="!incidentsFilterService.isFloorTable"
          [disabled]="!incidentsFilterService.selectedBuilding"
          [expanded]="step === 4"
          (opened)="step = 4">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="mobile-single-select-title">
                Floor @if (incidentsFilterService.selectedFloor) { :
                <span class="test-floor-selected-value font-normal">{{ incidentsFilterService.selectedFloor }}</span>
                }
              </div>
              <button
                *ngIf="incidentsFilterService.selectedFloor"
                class="test-floor-filter-clear-btn mobile-filter-clear-btn"
                (click)="clearSelectedFloor($event, 4)">
                Clear
              </button>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="test-floor-select mobile-single-filter-select">
            <li
              *ngFor="let floor of incidentsFilterService.floorOptions | sort : 'asc' : 'title' : 'all'"
              [class]="'test-floor-option-' + (floor.value || 'all')"
              [class.checked]="floor.value === incidentsFilterService.selectedFloor"
              (click)="incidentsFilterService.selectedFloor = floor.value">
              <span>{{ floor.title }}</span>
              <i class="icon-check text-ui-green-light text-3xl"></i>
            </li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel
          *ngIf="!incidentsFilterService.isRoomTable"
          class="test-room-panel"
          [disabled]="!incidentsFilterService.selectedFloor"
          [expanded]="step === 5"
          (opened)="step = 5">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="mobile-single-select-title">
                Room @if (incidentsFilterService.selectedRoom) { :
                <span class="test-room-selected-value font-normal">{{ incidentsFilterService.selectedRoom }}</span>
                }
              </div>
              <button
                *ngIf="incidentsFilterService.selectedRoom"
                class="test-room-filter-clear-btn mobile-filter-clear-btn"
                (click)="clearSelectedRoom($event)">
                Clear
              </button>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="test-room-select mobile-single-filter-select">
            <li
              *ngFor="let room of incidentsFilterService.roomOptions | sort : 'asc' : 'title' : 'all'"
              [class]="'test-room-option-' + (room.value || 'all')"
              [class.checked]="room.value === incidentsFilterService.selectedRoom"
              (click)="incidentsFilterService.selectedRoom = room.value">
              <span>{{ room.title }}</span>
              <i class="icon-check text-ui-green-light text-3xl"></i>
            </li>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div
      *ngIf="incidentsFilterService.incidentTabStatusFilter === incidentTabStatusFilterEnum.HISTORY"
      class="test-resolved-incdents-filters-wrapper">
      <div class="my-3 mx-6">
        <div class="flex">
          <mat-form-field class="date-range-picker w-full" (click)="resolvedPicker.open()">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="resolvedPicker" [formGroup]="incidentsFilterService.resolvedDateRange">
              <input [readonly]="true" matStartDate formControlName="start" placeholder="mm/dd/yyyy" />
              <input [readonly]="true" matEndDate formControlName="end" placeholder="mm/dd/yyyy" />
            </mat-date-range-input>
            <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
            <mat-datepicker-toggle matIconSuffix [for]="resolvedPicker" />
            <mat-date-range-picker #resolvedPicker (closed)="incidentsFilterService.calendarClose()" />
          </mat-form-field>
          <mat-spinner
            matSuffix
            *ngIf="incidentsFilterService.resolvedIncidentsLoading"
            class="ml-2"
            diameter="36"
            color="primary" />
        </div>
        <p
          class="mt-2 text-right"
          *ngIf="
            incidentsFilterService.initialResolvedDateStart !== incidentsFilterService.resolvedDateRange.value.start ||
            incidentsFilterService.initialResolvedDateEnd !== incidentsFilterService.resolvedDateRange.value.end
          ">
          <button class="mobile-filter-clear-btn text-sm" (click)="incidentsFilterService.resetResolvedDate()">
            Clear
          </button>
        </p>
      </div>

      <mat-accordion class="bottom-sheet-accordion">
        <mat-expansion-panel [expanded]="stepResolved === 1" (opened)="stepResolved = 1">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div>
                Device Type
                <span
                  *ngIf="incidentsFilterService.resolvedIncidentsDeviceTypeValue.length"
                  class="test-mobile-multiselect-device-type-inicator mobile-multiselect-filter-inicator"
                  >+{{ incidentsFilterService.resolvedIncidentsDeviceTypeValue.length }}</span
                >
              </div>
              <button
                *ngIf="incidentsFilterService.resolvedIncidentsDeviceTypeValue.length"
                class="test-mobile-device-type-clear-btn mobile-filter-clear-btn"
                (click)="clearResolvedIncidentsDeviceTypeFilter($event)">
                Clear
              </button>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="test-device-type-filter">
            <li
              *ngFor="
                let incident of incidentsFilterService.resolvedIncidentsDeviceTypeOptions | sort : 'asc' : 'title'
              ">
              <mat-checkbox
                class="full-width-checkbox"
                [class]="'test-device-type-option-' + incident.value"
                [value]="incident.value"
                (change)="
                  incidentsFilterService.updateResolvedIncidentsDeviceTypeValue({
                    value: $event.source.value,
                    checked: $event.checked
                  })
                "
                [checked]="incident.checked">
                {{ incident.title }}
              </mat-checkbox>
            </li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel
          *ngIf="!incidentsFilterService.isBuildingTable"
          [expanded]="stepResolved === 2"
          (opened)="stepResolved = 2">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="mobile-single-select-title">
                Building @if (incidentsFilterService.resolvedIncidentsSelectedBuilding) { :
                <span class="test-building-selected-value font-normal">{{
                  incidentsFilterService.resolvedIncidentsSelectedBuilding
                }}</span>
                }
              </div>
              <button
                *ngIf="incidentsFilterService.resolvedIncidentsSelectedBuilding"
                class="test-building-filter-clear-btn mobile-filter-clear-btn"
                (click)="clearResolvedIncidentsSelectedBuilding($event, 2)">
                Clear
              </button>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="test-building-select mobile-single-filter-select">
            <li
              *ngFor="
                let building of incidentsFilterService.resolvedIncidentsBuildingOptions | sort : 'asc' : 'title' : 'all'
              "
              [class]="'test-building-option-' + (building.value || 'all')"
              [class.checked]="building.value === incidentsFilterService.resolvedIncidentsSelectedBuilding"
              (click)="incidentsFilterService.resolvedIncidentsSelectedBuilding = building.value">
              <span>{{ building.title }}</span>
              <i class="icon-check text-ui-green-light text-3xl"></i>
            </li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel
          *ngIf="!incidentsFilterService.isFloorTable"
          class="test-floor-panel"
          [expanded]="stepResolved === 3"
          (opened)="stepResolved = 3"
          [disabled]="!incidentsFilterService.resolvedIncidentsSelectedBuilding">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="mobile-single-select-title">
                Floor @if (incidentsFilterService.resolvedIncidentsSelectedFloor) { :
                <span class="test-floor-selected-value font-normal">{{
                  incidentsFilterService.resolvedIncidentsSelectedFloor
                }}</span>
                }
              </div>
              <button
                *ngIf="incidentsFilterService.resolvedIncidentsSelectedFloor"
                class="test-floor-filter-clear-btn mobile-filter-clear-btn"
                (click)="clearResolvedIncidentsSelectedFloor($event, 3)">
                Clear
              </button>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="test-floor-select mobile-single-filter-select">
            <li
              *ngFor="
                let floor of incidentsFilterService.resolvedIncidentsFloorOptions | sort : 'asc' : 'title' : 'all'
              "
              [class]="'test-floor-option-' + (floor.value || 'all')"
              [class.checked]="floor.value === incidentsFilterService.resolvedIncidentsSelectedFloor"
              (click)="incidentsFilterService.resolvedIncidentsSelectedFloor = floor.value">
              <span>{{ floor.title }}</span>
              <i class="icon-check text-ui-green-light text-3xl"></i>
            </li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel
          *ngIf="!incidentsFilterService.isRoomTable"
          class="test-room-panel"
          [expanded]="stepResolved === 4"
          (opened)="stepResolved = 4"
          [disabled]="!incidentsFilterService.resolvedIncidentsSelectedFloor">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="mobile-single-select-title">
                Room @if (incidentsFilterService.resolvedIncidentsSelectedRoom) { :
                <span class="test-room-selected-value font-normal">{{
                  incidentsFilterService.resolvedIncidentsSelectedRoom
                }}</span>
                }
              </div>
              <button
                *ngIf="incidentsFilterService.resolvedIncidentsSelectedRoom"
                class="test-room-filter-clear-btn mobile-filter-clear-btn"
                (click)="clearResolvedIncidentsSelectedRoom($event)">
                Clear
              </button>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="test-room-select mobile-single-filter-select">
            <li
              *ngFor="let room of incidentsFilterService.resolvedIncidentsRoomOptions | sort : 'asc' : 'title' : 'all'"
              [class]="'test-room-option-' + (room.value || 'all')"
              [class.checked]="room.value === incidentsFilterService.resolvedIncidentsSelectedRoom"
              (click)="incidentsFilterService.resolvedIncidentsSelectedRoom = room.value">
              <span>{{ room.title }}</span>
              <i class="icon-check text-ui-green-light text-3xl"></i>
            </li>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
