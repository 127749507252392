<div *ngIf="device$ | async as device; else loading" class="test-device-details-wrapper">
  <header class="flex justify-between">
    <app-mobile-nested-page-header [directLink]="'devices'" [title]="device.friendlyName" titleName="Device:" />
    <app-mobile-mobile-buttons-bar
      [isPaused]="device.status === EntityStatus.Paused"
      [isArchived]="device.status === EntityStatus.Archived"
      (statusTrigger)="chageStatus(device)" />
  </header>

  <mat-card class="card">
    <ul class="main-details-list">
      <li>
        <span>Building: </span>
        <a class="underline" [routerLink]="['/dashboard', device.location.id]"
          ><strong>{{ device.location.friendlyName }}</strong></a
        >
      </li>
      <li>
        <span>Address: </span>
        {{ device.location.address.streetAddress + ', ' + device.location.address.city }}
      </li>
      <li>
        <span>Device Status: </span>
        <app-entity-status fontSize="base" [status]="device.status" />
      </li>
    </ul>
  </mat-card>

  <mat-card class="card divider-card mt-3">
    <div>
      <div>Floor:</div>
      <div>
        <a
          class="underline text-xl font-semibold"
          [routerLink]="['/dashboard', device.location.id, device.spacePath?.[0]?.id]"
          >{{ device.spacePath?.[0]?.friendlyName }}</a
        >
      </div>
    </div>
    <div class="divider">
      <div></div>
      <div class="line-block"></div>
      <div></div>
    </div>
    <div>
      <div>Room:</div>
      <div>
        <a
          class="underline text-xl font-semibold"
          [routerLink]="['/dashboard', device.location.id, device.spacePath?.[0]?.id, device.spacePath?.[1]?.id]"
          >{{ device.spacePath?.[1]?.friendlyName }}</a
        >
      </div>
    </div>
  </mat-card>

  <div class="border-b-ui-gray-light border-b flex items-center justify-between my-3">
    <mat-button-toggle-group
      class="mobile-toggle-group"
      aria-label="Device Tabs"
      [value]="tab"
      (change)="tab = $event.value">
      <mat-button-toggle [value]="TabValues.DEVICE_INFO" class="test-device-info-tab-btn">
        <span class="item-status-title">Info</span>
      </mat-button-toggle>
      <mat-button-toggle [value]="TabValues.INCIDENTS" class="test-incidents-tab-btn">
        <span class="item-status-title">Incidents</span>
        <span *ngIf="incidentsFilterService.newIncidentsAmount" class="incidents-amount-indicator ml-2">{{
          incidentsFilterService.newIncidentsAmount
        }}</span>
        <span
          *ngIf="incidentsFilterService.inProgressIncidentsAmount"
          class="incidents-amount-indicator bg-ui-yellow-100 text-ui-yellow-350 ml-2"
          >{{ incidentsFilterService.inProgressIncidentsAmount }}</span
        >
      </mat-button-toggle>
      <mat-button-toggle [value]="TabValues.CHARTS" class="test-charts-tab-btn">
        <span class="item-status-title">Data Point</span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <app-mobile-device-info
    *ngIf="clients$ | async as clients"
    class="test-device-info-tab"
    [class]="tab === TabValues.DEVICE_INFO ? 'visible' : 'is-hidden-tab'"
    [device]="device"
    [clientId]="clients[0].id" />

  <div [class]="tab === TabValues.INCIDENTS ? 'visible' : 'is-hidden-tab'" class="test-incidents-tab">
    <div class="mb-3 flex items-center">
      <mat-button-toggle-group
        [value]="incidentsFilterService.incidentTabStatusFilter"
        class="mobile-main-toggle-group"
        name="Incident status"
        (change)="incidentsFilterService.incidentTabStatusFilter = $event.value">
        <mat-button-toggle [value]="incidentsFilterService.incidentTabStatusFilterEnum.OPEN">
          Open
          <span *ngIf="incidentsFilterService.newIncidentsAmount" class="incidents-amount-indicator ml-2">{{
            incidentsFilterService.newIncidentsAmount
          }}</span>
          <span
            *ngIf="incidentsFilterService.inProgressIncidentsAmount"
            class="incidents-amount-indicator bg-ui-yellow-100 text-ui-yellow-350 ml-2"
            >{{ incidentsFilterService.inProgressIncidentsAmount }}</span
          >
        </mat-button-toggle>
        <mat-button-toggle [value]="incidentsFilterService.incidentTabStatusFilterEnum.HISTORY">
          History
        </mat-button-toggle>
      </mat-button-toggle-group>
      <mat-spinner *ngIf="incidentsFilterService.resolvedIncidentsLoading" diameter="40" class="ml-1"></mat-spinner>
    </div>

    <mat-form-field
      class="date-range-picker w-full"
      *ngIf="
        incidentsFilterService.incidentTabStatusFilter === incidentsFilterService.incidentTabStatusFilterEnum.HISTORY
      "
      (click)="resolvedPicker.open()">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="resolvedPicker" [formGroup]="incidentsFilterService.resolvedDateRange">
        <input [readonly]="true" matStartDate formControlName="start" placeholder="mm/dd/yyyy" />
        <input [readonly]="true" matEndDate formControlName="end" placeholder="mm/dd/yyyy" />
      </mat-date-range-input>
      <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
      <mat-datepicker-toggle matIconSuffix [for]="resolvedPicker" />
      <mat-date-range-picker #resolvedPicker (closed)="incidentsFilterService.calendarClose()" />
    </mat-form-field>

    <app-incidents-table noDataText="This device is working properly" [isMobile]="true" />
  </div>
  <app-device-stats-chart
    class="test-charts-tab"
    [class]="tab === TabValues.CHARTS ? 'visible' : 'is-hidden-tab'"
    [device]="device"
    [isMobile]="true" />
</div>

<ng-template #loading>
  <app-mobile-space-skeleton-animation class="test-skeleton-animation" />
</ng-template>
