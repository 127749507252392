import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DeviceData, EntityStatus, IncidentStatus, UserClient } from '@models';
import { AppState, clientList, getDeviceById, selectIncidentsByDeviceId, updateDeviceData } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { AppService, IncidentsFilterService, IncidentsService } from '@services';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';

export enum Tab {
  DEVICE_INFO = 'device-info',
  INCIDENTS = 'incidents',
  CHARTS = 'charts',
}

@Component({
  selector: 'app-mobile-device-details',
  templateUrl: './device-details.component.html',
})
export class DeviceDetailsComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  protected readonly EntityStatus = EntityStatus;
  protected readonly TabValues = Tab;
  device$: Observable<DeviceData | undefined> | undefined;
  clients$: Observable<UserClient[] | undefined>;
  tab: Tab = Tab.DEVICE_INFO;
  incidentStatus = IncidentStatus;

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private dialog: MatDialog,
    public incidentsFilterService: IncidentsFilterService,
    private appService: AppService,
    private incidentsService: IncidentsService
  ) {
    this.clients$ = this.store.select(clientList);

    const deviceId = this.route.snapshot.paramMap.get('deviceId');
    if (deviceId) {
      this.device$ = this.store.select(getDeviceById(deviceId));

      this.store
        .select(selectIncidentsByDeviceId(deviceId))
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(incidents => {
          this.incidentsFilterService.initFilterTableType({ displayedColumns: ['status', 'reported'] });
          this.incidentsFilterService.initOpenIncidents(incidents);
        });

      this.incidentsFilterService.resolvedDates$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(value => this.getResolvedIncidents(value, deviceId));
    }
  }

  ngOnInit() {
    const tab: Tab = this.route.snapshot.queryParams['tab'];

    if (Object.values(Tab).includes(tab)) {
      this.tab = tab;
    }
  }

  chageStatus(device: DeviceData) {
    const isActive = device.status !== EntityStatus.Paused;
    const title = isActive ? 'Pause' : 'Resume';
    const description = `Are you sure you want to ${isActive ? 'pause' : 'resume'} the ${device.friendlyName}?`;
    const status = isActive ? EntityStatus.Paused : EntityStatus.Active;

    ConfirmationDialogComponent.open(this.dialog, {
      title,
      description,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.store.dispatch(
            updateDeviceData({
              locationId: device.location.id,
              deviceId: device.id,
              data: { status },
            })
          );
        }
      });
  }

  getResolvedIncidents(dateRange: { startDateTime: string; endDateTime: string }, deviceId: string) {
    if (dateRange.startDateTime && dateRange.startDateTime && deviceId) {
      this.incidentsFilterService.resolvedIncidentsLoading = true;
      const params: { [key: string]: string } = { ...dateRange, deviceId: deviceId };
      this.incidentsService
        .getAllResolvedIncidents(this.appService.currentClient, params)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: data => {
            this.incidentsFilterService.initResolvedIncidents(data);
            this.incidentsFilterService.resolvedIncidentsLoading = false;
          },
          error: () => {
            this.incidentsFilterService.resolvedIncidentsLoading = false;
          },
        });
    }
  }
}
