import { Component, DestroyRef, inject } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BuildingFiltersSheetComponent } from '../../components/building-filters-sheet/building-filters-sheet.component';
import { AppService, BuildingDataFilterService, MapFilterService } from '@services';
import { MapFiltersSheetComponent } from '../../components/map-filters-sheet/map-filters-sheet.component';
import { Observable } from 'rxjs';
import { AppState, areLocationsLoaded } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { ScreenOrientations } from '@models';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type DashboardTab = 'table' | 'map';

@Component({
  selector: 'app-mobile-dashboard-main',
  templateUrl: './dashboard-main.component.html',
})
export class DashboardMainComponent {
  protected readonly ScreenOrientations = ScreenOrientations;
  destroyRef = inject(DestroyRef);
  tab: DashboardTab = 'table';
  isFullScreen = false;
  locationsLoaded$: Observable<boolean>;

  constructor(
    private bottomSheet: MatBottomSheet,
    public buildingDataFilterService: BuildingDataFilterService,
    public mapFilterService: MapFilterService,
    private store: Store<AppState>,
    public appService: AppService,
    router: Router
  ) {
    this.locationsLoaded$ = this.store.select(areLocationsLoaded);
    router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
      if (event instanceof NavigationEnd) this.tab = 'table';
    });
  }

  get selectedFilters(): boolean {
    return !!(this.buildingDataFilterService.statusFilter || this.buildingDataFilterService.incidentFilter?.length);
  }

  openBuildingFiltersBottomSheet(): void {
    this.bottomSheet.open(BuildingFiltersSheetComponent);
  }

  openMapFiltersBottomSheet(): void {
    this.bottomSheet.open(MapFiltersSheetComponent);
  }
}
