import { Component, Input } from '@angular/core';
import { SpaceStats, IncidentStatus } from '@models';

@Component({
  selector: 'app-mobile-incident-info-block',
  templateUrl: './incident-info-block.component.html',
  styleUrl: './incident-info-block.component.scss',
})
export class IncidentInfoBlockComponent {
  @Input() spaceStats!: SpaceStats;
  protected readonly IncidentStatus = IncidentStatus;
}
