import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IncidentsMainPageComponent } from './containers/incidents-main-page/incidents-main-page.component';
import { RouterModule } from '@angular/router';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { IncidentFiltersSheetComponent } from './components/incident-filters-sheet/incident-filters-sheet.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PipesModule } from '@pipes';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IncidentDetailsComponent } from './containers/incident-details/incident-details.component';
import { NestedPageHeaderComponent } from '@standalone/nested-page-header/nested-page-header.component';
import { MatCard } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { SpaceSkeletonAnimationComponent } from '@standalone/space-skeleton-animation/space-skeleton-animation.component';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { ImgLoaderComponent } from '@standalone/img-loader/img-loader.component';
import { BottomSheetMobileHeaderComponent } from '@standalone/bottom-sheet-mobile-header/bottom-sheet-mobile-header.component';
import { EntityStatusComponent } from '@standalone/entity-status/entity-status.component';
import { IncidentStatusComponent } from '@standalone/incident-status/incident-status.component';
import { IncidentsTableComponent } from '@standalone/_tables/incidents-table/incidents-table.component';
import { SkeletonTableComponent } from '@standalone/skeleton-table/skeleton-table.component';

@NgModule({
  declarations: [IncidentsMainPageComponent, IncidentFiltersSheetComponent, IncidentDetailsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: IncidentsMainPageComponent,
        data: { showMenu: true },
      },
      {
        path: ':id',
        component: IncidentDetailsComponent,
        data: { showMenu: false },
      },
    ]),
    MatButtonToggleModule,
    MatExpansionModule,
    MatCheckboxModule,
    PipesModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressSpinnerModule,
    NestedPageHeaderComponent,
    MatCard,
    MatInputModule,
    SpaceSkeletonAnimationComponent,
    SearchInputComponent,
    ImgLoaderComponent,
    BottomSheetMobileHeaderComponent,
    EntityStatusComponent,
    IncidentStatusComponent,
    IncidentsTableComponent,
    SkeletonTableComponent,
  ],
})
export class IncidentsModule {}
