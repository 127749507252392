import { EntityStatus, FloorSpaceModel, RoomSpaceModel } from './index';

export interface LocationBaseModel {
  name: string;
  friendlyName: string;
  clientFriendlyId: string;
  address: {
    streetAddress: string;
    streetAddress2: string;
    city: string;
    stateProvince: string;
    postalCode: string;
    country: string;
  };
  geographic: {
    lat: number;
    long: number;
  };
  status: EntityStatus;
}

export interface LocationLogsData {
  id: string;
  updated: string;
  location: string;
  action: string;
  user: string;
}

export interface DeviceLocation {
  siteId: LocationData['id'];
  floorId: FloorSpaceModel['id'];
  roomId: RoomSpaceModel['id'];
}

export interface SpaceStats {
  incidentAlert: number;
  incidentInProgress: number;
  deviceActive: number;
  devicePaused: number;
  rooms: number;
  floors: number;
}

export const initialStats = (): SpaceStats => {
  return {
    incidentAlert: 0,
    incidentInProgress: 0,
    deviceActive: 0,
    devicePaused: 0,
    rooms: 0,
    floors: 0,
  };
};

export interface ImageDocument {
  fileName: string;
  contentType: string;
  uploadedOn: string;
  fileSizeInBytes: number;
}

export interface WidgetModel {
  id: string;
  name: string;
  building: {
    id: string;
    value: string;
  };
  floor?: {
    id: string;
    value: string;
  };
  room?: {
    id: string;
    value: string;
  };
  incidents: number;
  devices: number;
  deviceAlert: number;
  deviceWarning: number;
  deviceGood: number;
  deviceOffline: number;
}

export interface WidgetCreationBase {
  name: string;
  buildingId: string;
  floorId?: string;
  roomId?: string;
}

export interface LocationData {
  tags: {
    [key: string]: string;
  };
  importantDates?: {
    provisioningInfo: {
      dateTime: string;
    };
    installationInfo: {
      dateTime: string;
    };
    comissioningInfo: {
      dateTime: string;
    };
    decomissionedInfo: {
      dateTime: string;
    };
  };
  id: string;
  status: EntityStatus;
  name: string;
  friendlyName: string;
  clientFriendlyId: string;
  address: LocationAddress;
  geographic: {
    lat: number;
    long: number;
  };
  devicesCount?: number;
  devicesByStatuses?: {
    activeCount: number;
    deactivatedCount: number;
    pausedCount: number;
  };
  incidentCountByStatuses?: {
    newCount: number;
    inProgressCount: number;
  };
}

export interface EntitySummary {
  devicesCount: number;
  devicesByStatuses: {
    activeCount: number;
    deactivatedCount: number;
    pausedCount: number;
  };
  incidentCountByStatuses: {
    newCount: number;
    inProgressCount: number;
  };
}

export interface LocationAddress {
  streetAddress: string;
  streetAddress2: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  country: string;
}

export interface LocationFullData extends LocationData {
  floors: FloorSpaceModel[];
}
