import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppService, IncidentsService, NotificationsService } from '@services';
import { catchError, interval, map, startWith, switchMap, takeUntil } from 'rxjs';
import { loadAllIncidentError, loadAllIncidentSuccess } from './incidents.actions';
import { AuthActions, IncidentsActions } from '../action-types';

@Injectable()
export class IncidentsEffects {
  constructor(
    private actions$: Actions,
    private incidentService: IncidentsService,
    private notificationService: NotificationsService,
    private appService: AppService
  ) {}

  loadAllIncidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentsActions.loadAllIncidents),
      switchMap(
        // getting incidents' info once in 10sec
        () => interval(1000 * 10).pipe(startWith(0), takeUntil(this.actions$.pipe(ofType(AuthActions.logout))))
      ),
      switchMap(() =>
        this.incidentService.getAllIncidents(this.appService.currentClient).pipe(
          map(incidents => loadAllIncidentSuccess({ incidents })),
          catchError(async error => {
            this.notificationService.showErrorMessage(error.message);
            return loadAllIncidentError({ error });
          })
        )
      )
    )
  );
}
