import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UserModel } from '@models';
import { Store } from '@ngrx/store';
import { AppState, selectUser } from '@ngrx-store';

@Component({
  selector: 'app-mobile-user-info',
  templateUrl: './user-info.component.html',
  styleUrl: './user-info.component.scss',
})
export class UserInfoComponent {
  user$: Observable<UserModel | undefined>;

  constructor(private store: Store<AppState>) {
    this.user$ = this.store.select(selectUser);
  }
}
