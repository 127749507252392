import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { DirectivesModule } from '@directives';
import { MatPrefix } from '@angular/material/form-field';
import { AsyncPipe, NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-mobile-nested-page-header',
  standalone: true,
  imports: [MatIcon, DirectivesModule, MatPrefix, NgIf, AsyncPipe, RouterLink],
  styles: [],
  template: `
    <h1 class="mobile-page-main-title flex pb-6 items-center">
      <mat-icon
        class="text-4xl mr-3 ml-[-9px]"
        appBackButton
        [directLink]="directLink"
        [simpleBackLink]="simpleBackLink"
        matPrefix
        >chevron_left
      </mat-icon>
      <i *ngIf="backHomeLink" routerLink="/dashboard" class="icon-dashboard-stroke text-3xl pr-4"></i>
      <div>
        <div *ngIf="titleName" class="text-base font-medium text-ui-gray-100 pb-0">{{ titleName }}</div>
        <div class="leading-none">{{ title }}</div>
      </div>
    </h1>
  `,
})
export class NestedPageHeaderComponent {
  @Input() titleName!: string;
  @Input() title: string = '';
  @Input() backHomeLink = false;
  @Input() simpleBackLink = false;
  @Input() directLink: string | null = null;
}
