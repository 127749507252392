<app-bottom-sheet-mobile-header (closeEvent)="close()" />
<mat-nav-list>
  <a routerLink="user-management" mat-list-item (click)="openLink($event)">
    <mat-icon matListItemIcon>badge</mat-icon>
    <span matListItemTitle>User Management</span>
  </a>

  <a mat-list-item (click)="logout($event)">
    <mat-icon matListItemIcon>logout</mat-icon>
    <span matListItemTitle>Log Out</span>
  </a>
</mat-nav-list>
