<app-bottom-sheet-mobile-header (closeEvent)="close()" />
<div class="mobile-action-sheet">
  <div class="mx-6 my-3 flex justify-between items-center">
    <h2 class="sheet-title">Filters</h2>
    <button
      *ngIf="devicesFilterService.selectedFilters"
      class="mobile-filter-clear-all-btn"
      (click)="devicesFilterService.clearAllFilters()">
      Clear All
    </button>
  </div>
  <div class="mobile-action-sheet-content">
    <mat-accordion class="bottom-sheet-accordion">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div>
              Device Type
              <span
                *ngIf="devicesFilterService.deviceTypeValue.length"
                class="test-mobile-multiselect-device-type-inicator mobile-multiselect-filter-inicator"
                >+{{ devicesFilterService.deviceTypeValue.length }}</span
              >
            </div>
            <button
              *ngIf="devicesFilterService.deviceTypeValue.length"
              class="test-mobile-device-type-clear-btn mobile-filter-clear-btn"
              (click)="clearDeviceTypeFilter($event)">
              Clear
            </button>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="test-device-type-filter">
          <li *ngFor="let deviceType of devicesFilterService.deviceTypeOptions | sort : 'asc' : 'title'">
            <mat-checkbox
              class="full-width-checkbox"
              [class]="'test-device-type-option-' + deviceType.value"
              [value]="deviceType.value"
              (change)="
                devicesFilterService.updateDeviceTypeValue({ value: $event.source.value, checked: $event.checked })
              "
              [checked]="deviceType.checked">
              {{ deviceType.title }}
            </mat-checkbox>
          </li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div>
              Manufacturer
              <span
                *ngIf="devicesFilterService.manufacturerValue.length"
                class="test-mobile-multiselect-manufacturer-inicator mobile-multiselect-filter-inicator"
                >+{{ devicesFilterService.manufacturerValue.length }}</span
              >
            </div>
            <button
              *ngIf="devicesFilterService.manufacturerValue.length"
              class="test-mobile-manufacturer-clear-btn mobile-filter-clear-btn"
              (click)="clearManufacturerFilter($event)">
              Clear
            </button>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="test-manufacturer-filter">
          <li *ngFor="let manufacturer of devicesFilterService.manufacturerOptions | sort : 'asc' : 'title'">
            <mat-checkbox
              class="full-width-checkbox"
              [class]="'test-manufacturer-option-' + manufacturer.value"
              [value]="manufacturer.value"
              (change)="
                devicesFilterService.updateManufacturerValue({ value: $event.source.value, checked: $event.checked })
              "
              [checked]="manufacturer.checked">
              {{ manufacturer.title }}
            </mat-checkbox>
          </li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="devicesFilterService.showLocationFilter">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="mobile-single-select-title">
              Building @if(devicesFilterService.selectedBuilding) {:
              <span class="test-building-selected-value font-normal">{{ devicesFilterService.selectedBuilding }}</span
              >}
            </div>
            <button
              *ngIf="devicesFilterService.selectedBuilding"
              class="test-building-filter-clear-btn mobile-filter-clear-btn"
              (click)="clearSelectedBuilding($event)">
              Clear
            </button>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="test-building-select mobile-single-filter-select">
          <li
            *ngFor="let building of devicesFilterService.buildingOptions | sort : 'asc' : 'title' : 'all'"
            [class]="'test-building-option-' + (building.value || 'all')"
            [class.checked]="building.value === devicesFilterService.selectedBuilding"
            (click)="devicesFilterService.selectedBuilding = building.value">
            <span>{{ building.title }}</span>
            <i class="icon-check text-ui-green-light text-3xl"></i>
          </li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel
        *ngIf="devicesFilterService.showLocationFilter"
        [disabled]="!devicesFilterService.selectedBuilding"
        class="test-floor-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="mobile-single-select-title">
              Floor @if(devicesFilterService.selectedFloor) {:
              <span class="test-floor-selected-value font-normal">{{ devicesFilterService.selectedFloor }}</span
              >}
            </div>
            <button
              *ngIf="devicesFilterService.selectedFloor"
              class="test-floor-filter-clear-btn mobile-filter-clear-btn"
              (click)="clearSelectedFloor($event)">
              Clear
            </button>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="test-floor-select mobile-single-filter-select">
          <li
            *ngFor="let floor of devicesFilterService.floorOptions | sort : 'asc' : 'title' : 'all'"
            [class]="'test-floor-option-' + (floor.value || 'all')"
            [class.checked]="floor.value === devicesFilterService.selectedFloor"
            (click)="devicesFilterService.selectedFloor = floor.value">
            <span>{{ floor.title }}</span>
            <i class="icon-check text-ui-green-light text-3xl"></i>
          </li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel
        *ngIf="devicesFilterService.showLocationFilter"
        [disabled]="!devicesFilterService.selectedFloor"
        class="test-room-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="mobile-single-select-title">
              Room @if(devicesFilterService.selectedRoom) {:
              <span class="test-room-selected-value font-normal">{{ devicesFilterService.selectedRoom }}</span
              >}
            </div>
            <button
              *ngIf="devicesFilterService.selectedRoom"
              class="test-room-filter-clear-btn mobile-filter-clear-btn"
              (click)="clearSelectedRoom($event)">
              Clear
            </button>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="test-room-select mobile-single-filter-select">
          <li
            *ngFor="let room of devicesFilterService.roomOptions | sort : 'asc' : 'title' : 'all'"
            [class]="'test-room-option-' + (room.value || 'all')"
            [class.checked]="room.value === devicesFilterService.selectedRoom"
            (click)="devicesFilterService.selectedRoom = room.value">
            <span>{{ room.title }}</span>
            <i class="icon-check text-ui-green-light text-3xl"></i>
          </li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
