<div *ngIf="showTabs" class="flex pt-6 justify-between items-center border-b">
  <div>
    <ngx-skeleton-loader
      [ngClass]="{ invisible: !showFilters }"
      count="2"
      appearance="line"
      [theme]="{ height: '54px', width: '100px', marginRight: '8px' }" />
  </div>
  <div>
    <ngx-skeleton-loader appearance="line" [theme]="{ height: '34px', width: '240px' }" />
  </div>
</div>
<div class="flex pt-4 test-filter-block" *ngIf="showFilters">
  <ngx-skeleton-loader appearance="line" count="3" [theme]="{ height: '34px', width: '130px', marginRight: '8px' }" />
</div>
<div class="pt-4">
  <div *ngFor="let col of tableRowsCount" class="pb-1 test-table-row">
    <ngx-skeleton-loader appearance="line" [theme]="{ height: '34px', width: '30%', marginBottom: '0' }" />
    <ngx-skeleton-loader appearance="line" count="3" [theme]="{ height: '34px', width: '15%', marginBottom: '0' }" />
    <ngx-skeleton-loader appearance="line" [theme]="{ height: '34px', width: '25%', marginBottom: '0' }" />
  </div>
  <div class="text-right pt-4">
    <ngx-skeleton-loader [theme]="{ width: '50%', height: '34px' }" />
  </div>
</div>
