import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorSettingsDirective } from './paginator-settings.directive';
import { RestrictedElementDirective } from './restricted-element.directive';
import { SuperAdminRoleFilterDirective } from './super-admin-role-filter.directive';
import { UploadSpaceplanDirective } from './upload-spaceplan.directive';
import { BackButtonDirective } from './back-button.directive';
import { NoSpaceSelectDirective } from './no-space-select.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    PaginatorSettingsDirective,
    RestrictedElementDirective,
    SuperAdminRoleFilterDirective,
    UploadSpaceplanDirective,
    BackButtonDirective,
    NoSpaceSelectDirective,
  ],
  exports: [
    PaginatorSettingsDirective,
    RestrictedElementDirective,
    SuperAdminRoleFilterDirective,
    UploadSpaceplanDirective,
    BackButtonDirective,
    NoSpaceSelectDirective,
  ],
})
export class DirectivesModule {}
