import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { getDeviceAttributesByType } from '@app-lib';
import { DeviceAdditionalAttribute, DeviceAttributeType, DeviceData, EntityStatus, IncidentStatus } from '@models';

@Component({
  selector: 'app-mobile-device-info',
  templateUrl: './device-info.component.html',
})
export class DeviceInfoComponent implements OnChanges {
  @Input() device: DeviceData | undefined;
  @Input() clientId = '';
  incidentStatus = IncidentStatus;
  tempSensor: string | null | undefined = undefined;
  propertyAttributes: DeviceAdditionalAttribute[] = [];

  protected readonly EntityStatus = EntityStatus;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['device']) {
      this.initDeviceAttributes();
    }
  }

  getDeviceUrl(device: DeviceData): string {
    return device.tags?.['ipAddress'] + '/' + (device.tags?.['hyperlink'] || '');
  }

  initDeviceAttributes() {
    if (this.device?.additionalAttributes) {
      this.tempSensor = this.device.additionalAttributes?.find(attribute => attribute.name === 'tempSensor')?.value;
      this.propertyAttributes = getDeviceAttributesByType(
        this.device.additionalAttributes,
        DeviceAttributeType.PROPERTY
      );
    }
  }
}
