<mat-card class="card">
  <ul>
    <li>
      <label>Building: </label> <strong>{{ buildingName }}</strong>
    </li>
    <li><label>Address: </label> {{ buildingAddress }}</li>
    <li *ngIf="buildingStatus">
      <label>Building Status: </label>
      <app-entity-status fontSize="base" [status]="buildingStatus" />
    </li>
    <li *ngIf="floorStatus">
      <label>Floor Status: </label>
      <app-entity-status fontSize="base" [status]="floorStatus" />
    </li>
    <li *ngIf="roomStatus">
      <label>Room Status: </label>
      <app-entity-status fontSize="base" [status]="roomStatus" />
    </li>
  </ul>
</mat-card>
