import { Component, Input } from '@angular/core';
import { EntityStatus } from '@models';

@Component({
  selector: 'app-mobile-building-address-bar',
  templateUrl: './building-address-bar.component.html',
  styleUrl: './building-address-bar.component.scss',
})
export class BuildingAddressBarComponent {
  @Input() buildingName!: string;
  @Input() buildingAddress!: string;
  @Input() buildingStatus!: EntityStatus;
  @Input() floorStatus!: EntityStatus;
  @Input() roomStatus!: EntityStatus;
}
