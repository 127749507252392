import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserInfoComponent } from './containers/user-info/user-info.component';
import { RouterModule } from '@angular/router';
import { NestedPageHeaderComponent } from '@standalone/nested-page-header/nested-page-header.component';
import { PersonalProfileComponent } from '@standalone/personal-pofile/personal-profile.component';

@NgModule({
  declarations: [UserInfoComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: UserInfoComponent,
        data: { showMenu: false },
      },
    ]),
    NestedPageHeaderComponent,
    PersonalProfileComponent,
  ],
})
export class UserProfileModule {}
