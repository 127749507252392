import { Component, DestroyRef, Inject, inject } from '@angular/core';
import {
  AppState,
  clientIdChanged,
  isSuperAdmin,
  loadChildClients,
  selectChildClientById,
  selectChildClients,
} from '@ngrx-store';
import { Store } from '@ngrx/store';
import { AppService } from '@services';
import { Observable, Subscription } from 'rxjs';
import { ChildClient } from '@models';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatFabButton } from '@angular/material/button';
import { PipesModule } from '@pipes';
import { MatListItem, MatListItemIcon, MatListItemTitle, MatNavList } from '@angular/material/list';
import { Router, RouterLink } from '@angular/router';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { BottomSheetMobileHeaderComponent } from '@standalone/bottom-sheet-mobile-header/bottom-sheet-mobile-header.component';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-mobile-client-select',
  standalone: true,
  imports: [AsyncPipe, MatIcon, MatSuffix, MatButton, MatPrefix, MatFabButton],
  templateUrl: './client-select-mobile.component.html',
  styleUrl: './client-select-mobile.component.scss',
})
export class ClientSelectMobileComponent {
  protected readonly JSON = JSON;
  destroyRef = inject(DestroyRef);
  isSuperAdmin!: boolean;
  listOpened = false;
  isSuperAdminSubs$: Subscription | undefined;
  clientList$: Observable<ChildClient[]> | undefined;
  selectedClient$!: Observable<ChildClient | undefined>;

  constructor(
    private store: Store<AppState>,
    private appService: AppService,
    private bottomSheet: MatBottomSheet,
    private router: Router
  ) {
    this.isSuperAdminSubs$ = this.store
      .select(isSuperAdmin)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(isSuperAdmin => {
        this.isSuperAdmin = isSuperAdmin;
        if (isSuperAdmin) {
          this.store.dispatch(loadChildClients());
          this.clientList$ = this.store.select(selectChildClients);
          this.selectedClient$ = this.store.select(selectChildClientById(appService.currentClient));
        }
      });
  }

  openClientList(clientList: ChildClient[] | null, selectedClient: ChildClient | null | undefined) {
    this.listOpened = true;
    if (clientList?.length && selectedClient) {
      this.bottomSheet
        .open(ClientListSheetComponent, {
          data: {
            clientList,
            selectedClient,
          },
        })
        .afterDismissed()
        .subscribe((newClient: ChildClient) => {
          this.listOpened = false;
          if (newClient && newClient.id !== selectedClient.id) {
            this.appService.currentClient = newClient.id;
            this.store.dispatch(clientIdChanged());
            this.selectedClient$ = this.store.select(selectChildClientById(this.appService.currentClient));
            this.router.navigateByUrl('');
          }
        });
    }
  }
}

@Component({
  selector: 'app-mobile-client-list',
  template: `
    <app-bottom-sheet-mobile-header (closeEvent)="close()" />
    <div class="mobile-action-sheet">
      <div class="mobile-action-sheet-search-bar">
        <app-search-input inputWidth="100%" (searchDataEvent)="search = $event" />
      </div>

      <ul
        class="mobile-single-filter-select mobile-action-sheet-content"
        *ngIf="data.clientList | sort : 'asc' : 'friendlyName' | filterClientsByName : search as clients">
        @if (clients.length) {
        <li
          *ngFor="let client of clients"
          [class.checked]="client.id === data.selectedClient.id"
          (click)="changeClient(client)">
          <span>{{ client.friendlyName }}</span>
          <i class="icon-check text-ui-green-light text-3xl"></i>
        </li>
        } @else {
        <div class="text-base text-ui-gray-100 pt-2 text-center pb-6">No Results</div>
        }
      </ul>
    </div>
  `,
  standalone: true,
  imports: [
    PipesModule,
    NgForOf,
    MatIcon,
    MatListItem,
    MatListItemIcon,
    MatListItemTitle,
    MatNavList,
    RouterLink,
    NgIf,
    SearchInputComponent,
    BottomSheetMobileHeaderComponent,
  ],
})
class ClientListSheetComponent {
  search = '';

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ClientListSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { clientList: ChildClient[]; selectedClient: ChildClient }
  ) {}

  close() {
    this._bottomSheetRef.dismiss();
  }

  changeClient(newClient: ChildClient) {
    this._bottomSheetRef.dismiss(newClient);
  }
}
