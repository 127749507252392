<mat-card class="card" *ngIf="device">
  <h4 class="font-poppins text-2xl text-ui-dark font-semibold">{{ device.friendlyName }}</h4>
  <p class="font-nunito font-semibold text-base text-ui-dark mt-2 mb-0" *ngIf="device.tags?.['description']">
    {{ device.tags?.['description'] }}
  </p>

  <div
    *ngIf="device.status !== EntityStatus.Archived && device.incidentCountByStatuses as incidents"
    class="test-incident-status my-3">
    <app-incident-status [status]="incidentStatus.IN_QUEUE" *ngIf="incidents.newCount" />
    <app-incident-status
      [status]="incidentStatus.IN_PROGRESS"
      *ngIf="!incidents.newCount && incidents.inProgressCount" />

    <span
      *ngIf="!incidents.newCount && !incidents.inProgressCount"
      class="inline-flex justify-center items-center h-6 min-w-[24px] capitalize text-xs px-2 rounded-xl text-white bg-ui-green-light">
      Good
    </span>
  </div>

  <div>
    <app-img-loader
      loader="skeleton"
      *ngIf="device.deviceModelInformation && device.deviceModelInformation.documentReference && clientId"
      altText="device"
      errorSrc="assets/icons/image-error.svg"
      [imgSrc]="
        'clientId/' +
        clientId +
        '/library/standardMake/' +
        device.deviceModelInformation.make.id +
        '/standardModel/' +
        device.deviceModelInformation.id +
        '/document/' +
        device.deviceModelInformation.documentReference
      " />
    <img
      alt="Device Image"
      *ngIf="!device.deviceModelInformation?.documentReference"
      src="assets/icons/image-placeholder.svg" />
  </div>

  <ul class="incident-details-list mb-3">
    <li>
      <span>Device type:</span>
      <p>{{ device.deviceModelInformation?.deviceType }}</p>
    </li>
    <li>
      <span>Manufacturer: </span>
      <p>{{ device.deviceModelInformation?.make?.name }}</p>
    </li>
    <li>
      <span>Model: </span>
      <p>{{ device.deviceModelInformation?.name }}</p>
    </li>
    <li>
      <span>Serial №: </span>
      <p>{{ device.physicalDeviceId }}</p>
    </li>
    <li *ngIf="device.tags?.['ipAddress']">
      <span>Ip Address:</span>
      @if (device.tags?.['ipAddress'] === "N/A") {
      <p>{{ device.tags?.['ipAddress'] }}</p>
      } @else {
      <p>
        <a class="underline" [href]="'http://' + getDeviceUrl(device)" target="_blank">
          {{ device.tags?.['ipAddress'] }}
        </a>
      </p>
      }
    </li>
    <li *ngIf="device.tags?.['macAddress']">
      <span>Mac Address:</span>
      <p>{{ device.tags?.['macAddress'] }}</p>
    </li>

    <li *ngFor="let property of propertyAttributes">
      <div *ngIf="property.value && property.name !== 'ipAddress'">
        <span class="capitalize">{{ property.friendlyName || property.name }} : </span>
        <p>{{ property.value }}</p>
      </div>
    </li>

    <li *ngIf="tempSensor">
      <span>Current temperature:</span>
      <p>{{ tempSensor }} &deg;F</p>
    </li>
  </ul>
</mat-card>
