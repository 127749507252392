import { Component, Input } from '@angular/core';
import { MobileMenuItems } from '@app-mobile/modules/main/components/main-menu-mobile/mobile-main-menu-items';
import { MenuItemModel, ScreenOrientations } from '@models';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AppState, logout, selectInProgressIncidentsAmount, selectNewIncidentsAmount } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mobile-main-menu',
  templateUrl: './main-menu-mobile.component.html',
  styleUrl: './main-menu-mobile.component.scss',
})
export class MainMenuMobileComponent {
  @Input() orientation: ScreenOrientations = ScreenOrientations.PORTRAIT;
  readonly menuItems: MenuItemModel[] = MobileMenuItems;
  newIncidentsAmount$: Observable<number>;
  inProgressIncidentsAmount$: Observable<number>;

  constructor(private bottomSheet: MatBottomSheet, private store: Store<AppState>) {
    this.newIncidentsAmount$ = this.store.select(selectNewIncidentsAmount);
    this.inProgressIncidentsAmount$ = this.store.select(selectInProgressIncidentsAmount);
  }

  openBottomSheet(): void {
    this.bottomSheet.open(MoreMobileMenuSheetComponent);
  }

  logout($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(logout());
  }

  protected readonly ScreenOrientations = ScreenOrientations;
}

@Component({
  selector: 'app-mobile-more-main-menu',
  templateUrl: 'more-action-sheet.html',
})
export class MoreMobileMenuSheetComponent {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<MoreMobileMenuSheetComponent>,
    private store: Store<AppState>
  ) {}

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  logout($event: MouseEvent) {
    this.store.dispatch(logout());
    this._bottomSheetRef.dismiss();
    $event.preventDefault();
  }

  close() {
    this._bottomSheetRef.dismiss();
  }
}
