import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState, logout } from '@ngrx-store';
import { environment } from '@env/environment';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const user = localStorage.getItem('user');
    const accessToken = user && JSON.parse(user)['accessToken'];
    request = request.clone({
      url: environment.api + request.url,
      headers: accessToken ? request.headers.set('SecurityToken', accessToken) : undefined,
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.store.dispatch(logout());
        }

        return throwError({
          name: 'Error',
          message: error.error?.message || error.message,
          status: error.status,
        });
      })
    );
  }
}
