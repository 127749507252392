import { Component, DestroyRef, OnDestroy, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { incidentColumnOptions } from '@app-lib';
import { AppState, isIncidentLoaded, selectAllIncidents } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { AppService, IncidentsFilterService, IncidentsService } from '@services';
import { IncidentFiltersSheetComponent } from '../../components/incident-filters-sheet/incident-filters-sheet.component';
import { IncidentTabStatusFilter } from '@models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mobile-incidents-main-page',
  templateUrl: './incidents-main-page.component.html',
})
export class IncidentsMainPageComponent implements OnDestroy {
  destroyRef = inject(DestroyRef);
  displayedColumns = incidentColumnOptions.map(option => option.value);
  incidentTabStatusFilterEnum = IncidentTabStatusFilter;
  isIncidentLoaded$: Observable<boolean>;

  constructor(
    private bottomSheet: MatBottomSheet,
    private store: Store<AppState>,
    public incidentsFilterService: IncidentsFilterService,
    private incidentsService: IncidentsService,
    private appService: AppService
  ) {
    incidentsFilterService.initFilterTableType({ displayedColumns: this.displayedColumns });

    this.isIncidentLoaded$ = this.store.select(isIncidentLoaded);

    this.store
      .select(selectAllIncidents)
      .pipe(takeUntilDestroyed())
      .subscribe(incidents => {
        this.incidentsFilterService.initOpenIncidents(incidents);
      });

    this.incidentsFilterService.resolvedDates$
      .pipe(takeUntilDestroyed())
      .subscribe(value => this.getResolvedIncidents(value));
  }

  openIncidentFiltersBottomSheet() {
    this.bottomSheet.open(IncidentFiltersSheetComponent);
  }

  ngOnDestroy() {
    this.incidentsFilterService.resetState();
  }

  getResolvedIncidents(dateRange: { startDateTime: string; endDateTime: string }) {
    if (dateRange.startDateTime && dateRange.startDateTime) {
      this.incidentsFilterService.resolvedIncidentsLoading = true;
      this.incidentsService
        .getAllResolvedIncidents(this.appService.currentClient, dateRange)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: data => {
            this.incidentsFilterService.initResolvedIncidents(data);
            this.incidentsFilterService.resolvedIncidentsLoading = false;
          },
          error: () => {
            this.incidentsFilterService.resolvedIncidentsLoading = false;
            // TODO: show notification error
          },
        });
    }
  }
}
