import { createAction, props } from '@ngrx/store';
import {
  DeviceAttribute,
  DeviceData,
  DeviceFullModel,
  DeviceManufacturer,
  DeviceModel,
  NewDeviceModelData,
} from '@models';

export const resetDeviceEntity = createAction('[Device Entity Update] Reset device entity collection');
export const loadAllClientDevices = createAction('[Devices Resolver] Load all client devices');

export const loadDevicesError = createAction('[Devices Error] Load all client devices error', props<{ error: any }>());
export const allClientDevicesLoaded = createAction(
  '[Load Devices Effect] All  client devices loaded',
  props<{ devices: DeviceData[] }>()
);

export const addNewDevice = createAction(
  '[Add New Device] Add new device action',
  props<{ locationId: string; newDeviceData: Partial<DeviceData> }>()
);
export const addNewDeviceSuccess = createAction(
  '[Add New Device] Add new device action success',
  props<{ newDevice: DeviceData }>()
);
export const addNewDeviceError = createAction('[Add New Device] Add new device action error');

export const loadDeviceDetails = createAction(
  '[Load one device details] Get One device details action',
  props<{ locationId: string; deviceId: string }>()
);
export const loadDeviceDetailsSuccess = createAction(
  '[Load one device details success] Get One device details action success',
  props<{ deviceItem: DeviceData }>()
);

export const updateDeviceData = createAction(
  '[Update device data] Updating device data values',
  props<{ locationId: string; deviceId: string; data: Partial<DeviceData> }>()
);

export const deviceDataSuccessfullyUpdated = createAction(
  '[Update device data success] Device data values successfully updated',
  props<{ device: DeviceData; oldData: Partial<DeviceData> }>()
);

export const updateDeviceDataError = createAction('[Update device data error]');

export const loadDeviceCollectionBySpaceId = createAction(
  '[Load space devices by room id] Load devices by room id started',
  props<{ locationId: string; roomId: string }>()
);

export const loadDeviceCollectionBySpaceIdSuccess = createAction(
  '[Load space devices by room id successful] Load devices by room id successfully finished',
  props<{ devices: DeviceData[] }>()
);

export const loadDeviceManufacturers = createAction('[Device] Load device manufacturers');

export const loadDeviceManufacturersSuccess = createAction(
  '[Device] Load device manufacturers success',
  props<{ manufacturers: DeviceManufacturer[] }>()
);

export const loadDeviceModels = createAction('[Device] Load device model list');

export const loadDeviceModelsSuccess = createAction(
  '[Device] Load device model list success',
  props<{ deviceModels: DeviceModel[] }>()
);

export const loadDeviceAttributes = createAction('[Device] Load device attribute list');

export const loadDeviceAttributesSuccess = createAction(
  '[Device] Load device attribute list success',
  props<{ attributes: DeviceAttribute[] }>()
);

export const loadDeviceFullModel = createAction(
  '[Device] Load device full model info',
  props<{ clientId: string; manufacturerId: string; modelId: string }>()
);

export const loadDeviceFullModelSuccess = createAction(
  '[Device] Load device full model info success',
  props<{ fullModel: DeviceFullModel | null }>()
);

export const addNewManufacturer = createAction(
  '[Device] Add new manufacturer',
  props<{ clientId: string; newManufacturerData: Pick<DeviceManufacturer, 'name'> }>()
);
export const addNewManufacturerSuccess = createAction(
  '[Device] Add new manufacturer success',
  props<{ manufacturer: DeviceManufacturer }>()
);
export const addNewManufacturerError = createAction('[Device] Add new manufacturer error');

export const addNewDeviceModel = createAction(
  '[Device] Add new device model',
  props<{ clientId: string; makeId: string; newDevicdeModelData: NewDeviceModelData }>()
);
export const addNewDeviceModelSuccess = createAction(
  '[Device] Add new device model success',
  props<{ deviceModel: DeviceModel }>()
);
export const addNewDeviceModelError = createAction('[Device] Add new device model error');
