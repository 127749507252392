import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserManagementMainPageComponent } from './containers/user-management-main-page/user-management-main-page.component';
import { RouterModule } from '@angular/router';
import { NestedPageHeaderComponent } from '@standalone/nested-page-header/nested-page-header.component';
import { clientUsersResolver } from '@ngrx-store';
import { MatIconModule } from '@angular/material/icon';
import { DirectivesModule } from '@directives';
import { AddNewUserComponent } from './containers/add-new-user/add-new-user.component';
import { MatCardModule } from '@angular/material/card';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgxMaskDirective } from 'ngx-mask';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpaceSkeletonAnimationComponent } from '@standalone/space-skeleton-animation/space-skeleton-animation.component';
import { UserDetailsComponent } from '@standalone/user-details/user-details.component';
import { MobileButtonsBarComponent } from '@standalone/mobile-buttons-bar/mobile-buttons-bar.component';
import { MobileUserDetailsPageComponent } from '@app-mobile/modules/user-management/containers/user-details/user-details.component';
import { UsersTableComponent } from '@standalone/_tables/users-table/users-table.component';
import { RoleGuard } from '@app-lib';

@NgModule({
  declarations: [UserManagementMainPageComponent, AddNewUserComponent, MobileUserDetailsPageComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: UserManagementMainPageComponent,
        data: { showMenu: true },
        resolve: {
          userClientList: clientUsersResolver,
        },
      },
      {
        path: 'add-new-user',
        component: AddNewUserComponent,
        canActivate: [RoleGuard],
        data: {
          activeForAdmin: true,
          showMenu: false,
        },
      },
      {
        path: ':id',
        component: MobileUserDetailsPageComponent,
        data: {
          showMenu: false,
        },
        resolve: {
          userClientList: clientUsersResolver,
        },
      },
    ]),
    NestedPageHeaderComponent,
    MatIconModule,
    DirectivesModule,
    MatCardModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    NgxMaskDirective,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    SpaceSkeletonAnimationComponent,
    UserDetailsComponent,
    MobileButtonsBarComponent,
    UsersTableComponent,
  ],
})
export class UserManagementModule {}
