<div
  [routerLink]="['/dashboard', locationId, floorData.id]"
  class="flex w-full bg-ui-dark text-white mb-1 rounded-full p-1.5 font-poppins">
  <div class="w-1/5 pl-4 whitespace-nowrap overflow-ellipsis overflow-hidden pr-1 font-semibold">
    {{ floorData.friendlyName }}
  </div>
  <div class="progress-bar w-3/5 progress-bar flex h-[4px] mt-2.5 overflow-hidden">
    <div class="bg-ui-red-50" *ngIf="progressBar.alert" [style]="getStyle(progressBar.alert)"></div>
    <div class="bg-ui-yellow-100" *ngIf="progressBar.warning" [style]="getStyle(progressBar.warning)"></div>
    <div class="bg-ui-green-light" *ngIf="progressBar.good" [style]="getStyle(progressBar.good)"></div>
    <div class="bg-ui-gray-100" *ngIf="progressBar.paused" [style]="getStyle(progressBar.paused)"></div>
  </div>
  <div class="w-1/5 text-right pr-4">{{ floorData.rooms?.length || '0' }}</div>
</div>
