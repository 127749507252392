@if (locationData && currentFloor) { @let isLandscape = appService.screenOrientation === ScreenOrientations.LANDSCAPE;
<header class="flex">
  <app-mobile-nested-page-header
    class="flex-grow"
    [title]="currentFloor.friendlyName"
    [backHomeLink]="true"
    titleName="Floor" />
  <app-mobile-mobile-buttons-bar
    [isPaused]="currentFloor.status === EntityStatus.Paused"
    [isArchived]="currentFloor.status === EntityStatus.Archived"
    (statusTrigger)="pauseFloor()" />
</header>

<app-mobile-building-address-bar
  [buildingAddress]="locationData.address.streetAddress + ', ' + locationData.address.city"
  [buildingName]="locationData.friendlyName"
  [floorStatus]="currentFloor.status" />

<div [class.flex]="isLandscape">
  <mat-card class="flex-grow card divider-card mt-3" [class.mr-2]="isLandscape">
    <div>
      <div>Floor:</div>
      <div (click)="openFloorList()" class="false-dropdown">
        <div>
          <strong>{{ currentFloor.friendlyName }}</strong>
          <mat-icon class="ml-2" fontIcon="expand_more"></mat-icon>
        </div>
      </div>
    </div>
    <div class="divider">
      <div></div>
      <div class="line-block"></div>
      <div></div>
    </div>
    <div>
      <div>Total Rooms:</div>
      <div>
        <strong>{{ roomCount }}</strong>
      </div>
    </div>
  </mat-card>
  <app-mobile-incident-info-block class="flex-grow" [class.ml-2]="isLandscape" [spaceStats]="floorStats" />
</div>

<div class="border-b-ui-gray-light border-b flex items-center justify-between mb-3 pt-4">
  <mat-button-toggle-group
    class="mobile-toggle-group"
    aria-label="Dashboard Tabs"
    [value]="tab"
    (change)="tab = $event.value">
    <mat-button-toggle value="rooms">
      <span class="item-status-title">Rooms</span>
    </mat-button-toggle>
    <mat-button-toggle value="floorPlan" *ngIf="currentFloor.documentReference">
      <span class="item-status-title">Floor Plan</span>
    </mat-button-toggle>
    <mat-button-toggle value="incidents">
      <span class="item-status-title">Incidents</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
<div [class]="tab === Tabs.ROOMS ? 'visible' : 'is-hidden-tab'">
  <app-room-list-table
    [locationId]="locationData.id"
    [floorId]="currentFloor.id"
    [roomList]="currentFloor.rooms || []"
    [isMobile]="true" />
</div>
<div *ngIf="currentFloor.documentReference" [class]="tab === Tabs.FLOOR_PLAN ? 'visible' : 'is-hidden-tab'">
  <app-mobile-floorplan-tab [currentFloor]="currentFloor" [locationId]="locationId" class="block" />
</div>
<div [class]="tab === Tabs.INCIDENTS ? 'visible' : 'is-hidden-tab'">
  <app-mobile-incidents-tab [locationId]="locationId" [floorId]="floorId" />
</div>
} @else {
<app-mobile-space-skeleton-animation />
}
