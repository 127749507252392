import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, loadAllIncidents } from '@ngrx-store';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@services';
import { StartSettingsClass } from '@abstraction';
import { ScreenOrientations } from '@models';

@Component({
  selector: 'app-mobile-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent extends StartSettingsClass implements OnInit {
  @ViewChild('section') section: ElementRef | undefined;
  showMenu = false;
  isErrorPage = false;

  constructor(
    public override store: Store<AppState>,
    public override router: Router,
    public override appService: AppService,
    private route: ActivatedRoute
  ) {
    super(store, router, appService);

    appService.isMobile = true;

    this.onActivationEnd = event => {
      if (event.snapshot.data['showMenu'] !== undefined) {
        this.showMenu = !!event.snapshot.data['showMenu'];
      }
    };

    this.onUserSelected = isLoggedIn => {
      if (isLoggedIn && !this.appService.isInitailizedAppSubscription) {
        this.appService.isInitailizedAppSubscription = true;
        this.store.dispatch(loadAllIncidents());
      }
    };

    this.onNavigationEnd = () => {
      if (this.section) {
        this.section.nativeElement.scrollTop = 0;
      }
      this.isErrorPage = this.route.snapshot.firstChild?.data['isErrorPage'];
    };
  }

  handleOrientationChange(): void {
    const isLandscape = window.screen.orientation.type.includes('landscape');
    const isPrimary = window.screen.orientation.type.includes('primary');
    document.body.classList.add(isLandscape ? 'landscape' : 'portrait');
    document.body.classList.remove(isLandscape ? 'portrait' : 'landscape');
    document.body.classList[isLandscape && isPrimary ? 'add' : 'remove']('pl-8', 'primary');
    document.body.classList[isLandscape && !isPrimary ? 'add' : 'remove']('pr-8', 'non-primary');
    this.appService.screenOrientation = isLandscape ? ScreenOrientations.LANDSCAPE : ScreenOrientations.PORTRAIT;
  }

  async ngOnInit() {
    this.handleOrientationChange();
    screen.orientation.addEventListener('change', () => {
      this.handleOrientationChange();
    });
    this.init();
  }

  protected readonly ScreenOrientation = ScreenOrientations;
}
